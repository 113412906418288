
//Language Vietnamien

export default {
    Receipt:'Biên nhận',
    Pos_Settings:'Cài đặt điểm bán hàng',
    Note_to_customer:'Lưu ý cho khách hàng',
    Show_Note_to_customer:'Hiển thị ghi chú cho khách hàng',
    Show_barcode:'Chương trình mã vạch',
    Show_Tax_and_Discount:'Hiển thị thuế & chiết khấu & giao hàng',
    Show_Customer:'Cho khách hàng xem',
    Show_Email:'Hiển thị Email',
    Show_Phone:'Hiển thị điện thoại',
    Show_Address:'Hiển thị địa chỉ',
    DefaultLanguage:'Ngôn ngữ mặc định',
    footer:'chân trang',
    Received_Amount:'Số tiền đã nhận',
    Paying_Amount:'Số tiền thanh toán',
    Change:'Biến đổi',
    Paying_amount_is_greater_than_Received_amount:'Số tiền thanh toán lớn hơn số tiền đã nhận',
    Paying_amount_is_greater_than_Grand_Total:'Số tiền thanh toán lớn hơn Tổng số tiền',
    code_must_be_not_exist_already:'mã phải không tồn tại rồi',
    You_will_find_your_backup_on:'Bạn sẽ tìm thấy bản sao lưu của mình trên',
    and_save_it_to_your_pc:'và lưu nó vào máy tính của bạn',
    Scan_your_barcode_and_select_the_correct_symbology_below:'Quét mã vạch của bạn và chọn mã ký hiệu chính xác bên dưới',
    Scan_Search_Product_by_Code_Name:'Quét / Tìm kiếm sản phẩm theo tên mã',
    Paper_size:'Khổ giấy',
    Clear_Cache:'Xóa bộ nhớ cache',
    Cache_cleared_successfully:'Đã xóa bộ nhớ cache thành công',
    Failed_to_clear_cache:'Không xóa được bộ nhớ cache',
    Scan_Barcode:'Máy quét mã vạch',
    Please_use_short_name_of_unit:'Vui lòng sử dụng tên ngắn của đơn vị',
    DefaultCustomer:'Khách hàng mặc định',
    DefaultWarehouse:'Kho mặc định',
    Payment_Gateway:'Cổng thanh toán',
    SMS_Configuration:'Cấu hình SMS',
    Gateway:'Cổng thanh toán',
    Choose_Gateway:'Chọn Cổng thanh toán',
    Send_SMS :'Đã gửi tin nhắn thành công',
    sms_config_invalid:'sai cấu hình sms không hợp lệ',
    Remove_Stripe_Key_Secret:'Xóa các khóa API sọc',
    credit_card_account_not_available :'Tài khoản thẻ tín dụng không khả dụng',
    Credit_Card_Info:'Thông tin thẻ tín dụng',
    developed_by:'Được phát triển bởi',
    Unit_already_linked_with_sub_unit:'Đơn vị đã được liên kết với đơn vị phụ',
    Total_Items_Quantity : 'Tổng số mặt hàng và số lượng',
    Value_by_Cost_and_Price : 'Giá trị theo Chi phí và Giá cả',
    Search_this_table: 'Tìm kiếm bảng này',
    import_products:'Nhập sản phẩm',
    Field_optional:'Trường tùy chọn',
    Download_exemple:'Tải xuống ví dụ',
    field_must_be_in_csv_format:'Trường phải ở định dạng csv',
    Successfully_Imported:'Đã nhập thành công',
    file_size_must_be_less_than_1_mega:'Kích thước tệp phải nhỏ hơn 1 mega',
    Please_follow_the_import_instructions:'Vui lòng làm theo hướng dẫn nhập',
    must_be_exist:'đơn vị phải được tạo',
    Import_Customers:'Nhập khách hàng',
    Import_Suppliers:'Các nhà cung cấp nhập khẩu',
    Recent_Sales : 'Bán hàng gần đây',
    Create_Transfer : 'Tạo chuyển khoản',
    order_products : 'đặt hàng',
    Search_Product_by_Code_Name : 'Tìm kiếm sản phẩm theo mã hoặc tên',
    Reports_payments_Purchase_Return:'Báo cáo Mua hàng Trả lại Thanh toán',
    Reports_payments_Sale_Return:'Báo cáo các khoản thanh toán trả lại hàng bán',
    payments_Sales_Return:'thanh toán bán hàng trả lại',
    payments_Purchases_Return:'thanh toán mua hàng trả lại',
    CreateSaleReturn:'Tạo lợi nhuận bán hàng',
    EditSaleReturn:'Chỉnh sửa lợi nhuận bán hàng',
    SalesReturn:'Lợi nhuận bán hàng',
    CreatePurchaseReturn:'Tạo lợi tức mua hàng',
    EditPurchaseReturn:'Chỉnh sửa lợi tức mua hàng',
    PurchasesReturn:'Mua hàng trả lại',
    Due:'đến hạn',
    Profit: 'Lợi nhuận',
    Revenue: 'Doanh thu',
    Sales_today: 'Bán hàng hôm nay',
    People: 'Mọi người',
    Successfully_Created: 'Thành công trong việc tạo ra',
    Successfully_Updated: 'Cập nhật thành công',
    Success: 'Sự thành công',
    Failed: 'Thất bại',
    Warning: 'Cảnh báo',
    Please_fill_the_form_correctly: 'Vui lòng điền vào biểu mẫu một cách chính xác',
    Field_is_required: 'Lĩnh vực được yêu cầu',
    Error: 'lỗi!',
    you_are_not_authorized: 'Lấy làm tiếc! Bạn không được ủy quyền.',
    Go_back_to_home: 'Quay lại trang chủ',
    page_not_exist: 'Lấy làm tiếc! Trang bạn đang tìm kiếm không tồn tại.',
    Choose_Status: 'Chọn trạng thái',
    Choose_Method: 'Chọn phương pháp',
    Choose_Symbology: 'Chọn ký hiệu',
    Choose_Category: 'Chọn danh mục',
    Choose_Customer: 'Chọn khách hàng',
    Choose_Supplier: 'Chọn nhà cung cấp',
    Choose_Unit_Purchase: 'Chọn đơn vị mua hàng',
    Choose_Sub_Category: 'Chọn Danh mục con',
    Choose_Brand: 'Chọn thương hiệu',
    Choose_Warehouse: 'Chọn nhà kho',
    Choose_Unit_Sale: 'Chọn đơn vị bán hàng',
    Enter_Product_Cost: 'Nhập giá thành sản phẩm',
    Enter_Stock_alert: 'Nhập cảnh báo còn hàng',
    Choose_Unit_Product: 'Chọn đơn vị sản phẩm',
    Enter_Product_Price: 'Nhập giá sản phẩm',
    Enter_Name_Product: 'Nhập Tên Sản phẩm',
    Enter_Role_Name: 'Nhập tên vai trò',
    Enter_Role_Description: 'Nhập mô tả vai trò',
    Enter_name_category: 'Nhập tên danh mục',
    Enter_Code_category: 'Nhập mã danh mục',
    Enter_Name_Brand: 'Nhập tên thương hiệu',
    Enter_Description_Brand: 'Nhập nhãn hiệu mô tả',
    Enter_Code_Currency: 'Nhập tiền tệ mã',
    Enter_name_Currency: 'Nhập tên Đơn vị tiền tệ',
    Enter_Symbol_Currency: 'Nhập tiền tệ ký hiệu',
    Enter_Name_Unit: 'Nhập tên đơn vị',
    Enter_ShortName_Unit: 'Nhập tên viết tắt Đơn vị',
    Choose_Base_Unit: 'Chọn đơn vị cơ sở',
    Choose_Operator: 'Chọn nhà điều hành',
    Enter_Operation_Value: 'Nhập giá trị hoạt động',
    Enter_Name_Warehouse: 'Nhập tên kho',
    Enter_Phone_Warehouse: 'Nhập số điện thoại kho hàng',
    Enter_Country_Warehouse: 'Nhập quốc gia kho hàng',
    Enter_City_Warehouse: 'Nhập thành phố kho hàng',
    Enter_Email_Warehouse: 'Nhập Email Kho hàng',
    Enter_ZipCode_Warehouse: 'Nhập mã Zip kho hàng',
    Choose_Currency: 'Chọn tiền tệ',
    Thank_you_for_your_business: 'Cảm ơn bạn cho doanh nghiệp của bạn!',
    Cancel: 'Huỷ bỏ',
    New_Customer: 'Khách hàng mới',
    Incorrect_Login: 'Đăng nhập không chính xác',
    Successfully_Logged_In: 'Đã đăng nhập thành công',
    This_user_not_active: 'Người dùng này không hoạt động',
    SignIn: 'Đăng nhập',
    Create_an_account: 'Tạo một tài khoản',
    Forgot_Password: 'Quên mật khẩu ?',
    Email_Address: 'Địa chỉ email',
    SignUp: 'Đăng ký',
    Already_have_an_account: 'Bạn co săn san để tạo một tai khoản ?',
    Reset_Password: 'Đặt lại mật khẩu',
    Failed_to_authenticate_on_SMTP_server: 'Không xác thực được trên máy chủ SMTP',
    We_cant_find_a_user_with_that_email_addres: 'Chúng tôi không thể tìm thấy người dùng có địa chỉ email đó',
    We_have_emailed_your_password_reset_link: 'Chúng tôi đã gửi qua e-mail liên kết đặt lại mật khẩu của bạn',
    Please_fill_the_Email_Adress: 'Vui lòng điền vào Địa chỉ Email',
    Confirm_password: 'Xác nhận mật khẩu',
    Your_Password_has_been_changed: 'Mật khẩu của bạn đã được thay đổi',
    The_password_confirmation_does_not_match: 'Xác nhận mật khẩu không khớp',
    This_password_reset_token_is_invalid: 'Mã thông báo đặt lại mật khẩu này không hợp lệ',
    Warehouse_report: 'Báo cáo kho hàng',
    All_Warehouses: 'Tất cả các kho hàng',
    Expense_List: 'Danh sách chi phí',
    Expenses: 'Chi phí',
    This_Week_Sales_Purchases: 'Bán hàng và mua hàng trong tuần này',
    Top_Selling_Products: 'Sản phẩm bán chạy nhất',
    View_all: 'Xem tất cả',
    Payment_Sent_Received: 'Thanh toán đã Gửi và Nhận',
    Filter: 'Bộ lọc',
    Invoice_POS: 'POS xuất hóa đơn',
    Invoice: 'Hóa đơn',
    Customer_Info: 'thông tin khách hàng',
    Company_Info: 'Thông tin công ty',
    Invoice_Info: 'Thông tin hóa đơn',
    Order_Summary: 'Tóm tắt theo thứ tự',
    Quote_Info: 'Thông tin báo giá',
    Del: 'Xóa bỏ',
    SuppliersPaiementsReport: 'Báo cáo thanh toán cho nhà cung cấp',
    Purchase_Info: 'Thông tin mua hàng',
    Supplier_Info: 'Thông tin nhà cung cấp',
    Return_Info: 'thông tin về sự trở lại',
    Expense_Category: 'Hạng mục Chi phí',
    Create_Expense: 'Tạo chi phí',
    Details: 'Chi tiết',
    Discount_Method: 'Phương thức chiết khấu',
    Net_Unit_Cost: 'Đơn giá ròng',
    Net_Unit_Price: 'Đơn giá ròng',
    Edit_Expense: 'Chỉnh sửa chi phí',
    All_Brand: 'Tất cả thương hiệu',
    All_Category: 'Tất cả các loại',
    ListExpenses: 'Liệt kê chi phí',
    Create_Permission: 'Tạo quyền',
    Edit_Permission: 'Chỉnh sửa quyền',
    Reports_payments_Sales: 'Báo cáo thanh toán Bán hàng',
    Reports_payments_Purchases: 'Báo cáo thanh toán Mua hàng',
    Reports_payments_Return_Customers: 'Báo cáo thanh toán Khách hàng trả lại',
    Reports_payments_Return_Suppliers: 'Báo cáo thanh toán Trả lại nhà cung cấp',
    Expense_Deleted: 'Chi phí này đã bị xóa',
    Expense_Updated: 'Chi phí này đã được cập nhật',
    Expense_Created: 'Chi phí này đã được tạo',
    DemoVersion: 'Bạn không thể làm điều này trong phiên bản demo',
    OrderStatistics: 'Thống kê bán hàng',
    AlreadyAdd: 'Sản phẩm này đã được thêm vào !!',
    AddProductToList: 'Vui lòng thêm sản phẩm vào danh sách !!',
    AddQuantity: 'Vui lòng thêm số lượng Chi tiết !!',
    InvalidData: 'Dữ liệu không hợp lệ !!',
    LowStock: 'số lượng vượt quá số lượng có sẵn trong kho',
    WarehouseIdentical: 'Hai kho không thể giống hệt nhau !!',
    VariantDuplicate: 'Biến thể này là trùng lặp !!',
    Filesize: 'Kích thước tập tin',
    GenerateBackup: 'Tạo bản sao lưu',
    BackupDatabase: 'Cơ sở dữ liệu sao lưu',
    Backup: 'Sao lưu',
    Paid: 'Đã thanh toán',
    Unpaid: 'Chưa thanh toán',
    Today: 'Hôm nay',
    Income: 'Thu nhập',
    Expenses: 'Chi phí',
    Sale: 'Giảm giá',
    Actif: 'Hoạt động',
    Inactif: 'Không hoạt động',
    Customers: 'Khách hàng',
    Phone: 'Điện thoại',
    SearchByPhone: 'Tìm kiếm bằng điện thoại',
    Suppliers: 'Các nhà cung cấp',
    Quotations: 'Báo giá',
    Sales: 'Bán hàng',
    Purchases: 'Mua hàng',
    Returns: 'Lợi nhuận',
    Settings: 'Cài đặt',
    SystemSettings: 'Cài đặt hệ thống',
    Users: 'Người dùng',
    GroupPermissions: 'Quyền nhóm',
    Currencies: 'Tiền tệ',
    Warehouses: 'Nhà kho',
    Units: 'Các đơn vị',
    UnitsPrchases: 'Đơn vị mua hàng',
    UnitsSales: 'Đơn vị bán hàng',
    Reports: 'Báo cáo',
    PaymentsReport: 'Báo cáo thanh toán',
    PaymentsPurchases: 'Thanh toán Mua hàng',
    PaymentsSales: 'Thanh toán Bán hàng',
    ProfitandLoss: 'Lợi nhuận và thua lỗ',
    WarehouseStockChart: 'Biểu đồ kho hàng',
    SalesReport: 'Báo cáo bán hàng',
    PurchasesReport: 'Báo cáo mua hàng',
    CustomersReport: 'Báo cáo khách hàng',
    SuppliersReport: 'Báo cáo nhà cung cấp',
    SupplierReport: 'Báo cáo nhà cung cấp',
    DailySalesData: 'Dữ liệu bán hàng hàng ngày',
    DailyPurchasesData: 'Daily Purchases Data',
    Dernièrescinqrecords: 'Năm bản ghi cuối cùng',
    Filters: 'Bộ lọc',
    date: 'ngày',
    Reference: 'Tài liệu tham khảo',
    Supplier: 'Nhà cung cấp',
    PaymentStatus: 'Tình trạng thanh toán',
    Customer: 'khách hàng',
    CustomerCode: 'Mã khách hàng',
    Status: 'Trạng thái',
    SupplierCode: 'Mã nhà cung cấp',
    Categorie: 'thể loại',
    Categories: 'Thể loại',
    StockTransfers: 'Chuyển kho',
    StockManagement: 'Quản lý chứng khoán',
    dashboard: 'bảng điều khiển',
    Products: 'Các sản phẩm',
    productsList: 'danh sách sản phẩm',
    ProductManagement: 'Quản lý sản phẩm',
    ProductQuantityAlerts: 'Cảnh báo số lượng sản phẩm',
    CodeProduct: 'Mã sản phẩm',
    ProductTax: 'Thuế sản phẩm',
    SubCategorie: 'Danh mục con',
    Name_product: 'Chỉ định',
    StockAlert: 'Cảnh báo hàng tồn kho',
    warehouse: 'Kho',
    Tax: 'Thuế',
    BuyingPrice: 'Giá mua',
    SellPrice: 'Giá bán',
    Quantity: 'Định lượng',
    UnitSale: 'Bán đơn vị',
    UnitPurchase: 'Mua đơn vị',
    ManagementCurrencies: 'Quản lý tiền tệ',
    CurrencyCode: 'Mã tiền tệ',
    CurrencyName: 'Tên tiền tệ',
    Symbol: 'Biểu tượng',
    All: 'Tất cả',
    EditProduct: 'Chỉnh sửa sản phẩm',
    SearchByCode: 'Tìm kiếm theo mã',
    SearchByName: 'Tìm kiếm theo tên',
    ProductDetails: 'Thông tin chi tiết sản phẩm',
    CustomerName: 'tên khách hàng',
    CustomerManagement: 'Quản lý khách hàng',
    Add: 'Tạo nên',
    add: 'Tạo nên',
    Edit: 'Biên tập',
    Close: 'Đóng',
    PleaseSelect: 'Vui lòng chọn',
    Action: 'Hoạt động',
    Email: 'thư',
    EditCustomer: 'Chỉnh sửa khách hàng',
    AddCustomer: 'Tạo khách hàng',
    Country: 'Quốc gia',
    City: 'Tp.',
    Adress: 'Địa chỉ',
    CustomerDetails: 'Chi tiết khách hàng',
    CustomersList: 'Danh sách khách hàng',
    SupplierCode: 'Mã nhà cung cấp',
    SupplierName: 'tên đệm',
    SuppliersManagement: 'Quản lý nhà cung cấp',
    SupplierDetails: 'Thông tin chi tiết về nhà cung cấp',
    QuotationsManagement: 'Quản lý Báo giá',
    SubTotal: 'Tổng phụ',
    MontantReste: 'Số tiền còn lại',
    complete: 'hoàn thành',
    EnAttendant: 'đang chờ xử lý',
    Recu: 'Nhận',
    partial: 'Một phần',
    Retournee: 'Trở về',
    DetailQuote: 'Báo giá chi tiết',
    EditQuote: 'Chỉnh sửa Báo giá',
    CreateSale: 'Tạo bán hàng',
    DownloadPdf: 'Tải PDF',
    QuoteEmail: 'Báo giá đã gửi qua thư',
    DeleteQuote: 'Xóa báo giá',
    AddQuote: 'Tạo Báo giá',
    SelectProduct: 'Chọn sản phẩm',
    ProductCodeName: 'Product (Code - Name)',
    Price: 'Giá bán',
    CurrentStock: 'cổ phần',
    Total: 'Toàn bộ',
    Num: 'N°',
    Unitcost: 'Đơn giá',
    to: 'đến',
    Subject: 'Môn học',
    Message: 'Thông điệp',
    EmailCustomer: 'Gửi email cho khách hàng',
    Sent: 'Gửi',
    Quote: 'Bảng báo giá',
    Hello: 'xin chào',
    AttachmentQuote: 'Vui lòng tìm tệp đính kèm cho Báo giá của bạn',
    AddProducts: 'Thêm sản phẩm vào danh sách đặt hàng',
    SelectWarehouse: 'Vui lòng chọn kho',
    SelectCustomer: 'vui lòng chọn khách hàng',
    SalesManagement: 'Quản lý bán hàng',
    Balance: 'Thăng bằng',
    QtyBack: 'Số lượng trở lại',
    TotalReturn: 'Bắt đầu lại từ đầu',
    Amount: 'Số tiền',
    SaleDetail: 'Chi tiết giảm giá',
    EditSale: 'Chỉnh sửa giảm giá',
    AddSale: 'Tạo bán hàng',
    ShowPayment: 'Hiển thị các khoản thanh toán',
    AddPayment: 'Tạo thanh toán',
    EditPayment: 'Chỉnh sửa Thanh toán',
    EmailSale: 'Send Sale in Email',
    DeleteSale: 'Xóa giảm giá',
    ModePaiement: 'Được trả bởi',
    Paymentchoice: 'Lựa chọn thanh toán',
    Note: 'Ghi chú',
    PaymentComplete: 'Hoàn tất thanh toán!',
    PurchasesManagement: 'Quản lý mua hàng',
    Ordered: 'Đã đặt hàng',
    DeletePurchase: 'Xóa giao dịch mua',
    EmailPurchase: 'Gửi đơn mua hàng qua thư',
    EditPurchase: 'Chỉnh sửa giao dịch mua',
    PurchaseDetail: 'Chi tiết Mua hàng',
    AddPurchase: 'Tạo giao dịch mua',
    EmailSupplier: 'Thư nhà cung cấp',
    PurchaseInvoice: 'Thanh toán mua hàng',
    PurchasesInvoicesData: 'Mua dữ liệu thanh toán',
    SalesInvoice: 'Thanh toán bán hàng',
    SalesInvoicesData: 'Dữ liệu thanh toán bán hàng',
    UserManagement: 'quản lý người dùng',
    Firstname: 'Tên đầu tiên',
    lastname: 'họ',
    username: 'tên tài khoản',
    password: 'Mật khẩu',
    Newpassword: 'Mật khẩu mới',
    ChangeAvatar: 'Thay đổi hình đại diện',
    LeaveBlank: 'Vui lòng để trống trường này nếu bạn chưa thay đổi',
    type: 'kiểu',
    UserPermissions: 'Quyền của người dùng',
    RoleName: 'Vai trò',
    RoleDescription: 'Mô tả vai trò',
    AddPermissions: 'Tạo quyền',
    View: 'Lượt xem',
    Del: 'Xóa bỏ',
    NewAdjustement: 'Điều chỉnh mới',
    EditAdjustement: 'Chỉnh sửa điều chỉnh',
    CannotSubstraction: 'Bạn không thể trừ các sản phẩm có hàng 0',
    Addition: 'Thêm vào',
    Subtraction: 'Phép trừ',
    profil: 'hồ sơ',
    logout: 'đăng xuất',
    PurchaseAlreadyPaid: 'bạn không thể sửa đổi vì Giao dịch mua này đã được thanh toán',
    SaleAlreadyPaid: 'bạn không thể sửa đổi vì Giảm giá này đã được thanh toán',
    ReturnAlreadyPaid: 'bạn không thể sửa đổi vì lợi nhuận này đã được thanh toán',
    QuoteAlready: 'Báo giá này đã tạo ra doanh số bán hàng',
    AddProduct: 'Tạo sản phẩm',
    QuotationComplete: 'Báo giá này hoàn thành',
    SiteConfiguration: 'Cấu hình trang web',
    Language: 'Ngôn ngữ',
    DefaultCurrency: 'mặc định ngoại tệ',
    LoginCaptcha: 'Captcha đăng nhập',
    DefaultEmail: 'Thư mặc định',
    SiteName: 'Tên trang web',
    ChangeLogo: 'Thay đổi biểu trưng',
    SMTPConfiguration: 'Cấu hình SMTP',
    HOST: 'TỔ CHỨC',
    PORT: 'HẢI CẢNG',
    encryption: 'Mã hóa',
    SMTPIncorrect: 'Cấu hình SMTP không chính xác',
    PaymentsReturns: 'Thanh toán Lợi nhuận',
    ReturnsInvoices: 'Trả lại hóa đơn',
    ReturnsInvoicesData: 'Trả về dữ liệu hóa đơn',
    ShowAll: 'Hiển thị tất cả hồ sơ của tất cả Người dùng',
    Discount: 'Giảm giá',
    OrderTax: 'Thuế đặt hàng',
    Shipping: 'Đang chuyển hàng',
    CompanyName: 'Tên công ty',
    CompanyPhone: 'Điện thoại công ty',
    CompanyAdress: 'địa chỉ công ty',
    Code: 'Mã',
    image: 'hình ảnh',
    Printbarcode: 'In mã vạch',
    ReturnsCustomers: 'Trả lại khách hàng',
    ReturnsSuppliers: 'Trả lại nhà cung cấp',
    FactureReturnCustomers: 'Khách hàng trả lại hóa đơn',
    FactureReturnSuppliers: 'Trả lại hóa đơn cho nhà cung cấp',
    NodataAvailable: 'Không có dữ liệu',
    ProductImage: 'Hình ảnh sản phẩm',
    Barcode: 'Mã vạch',
    pointofsales: 'điểm ban hang',
    CustomUpload: 'Tải lên tùy chỉnh',
    pointofsaleManagement: 'quản lý điểm bán hàng',
    Adjustment: 'Điều chỉnh',
    Updat: 'Cập nhật',
    Reset: 'Cài lại',
    print: 'In',
    SearchByEmail: 'Tìm kiếm bằng Email',
    ChooseProduct: 'Chọn sản phẩm',
    Qty: 'Định lượng',
    Items: 'Mặt hàng',
    AmountHT: 'Số tiền',
    AmountTTC: 'Tổng số tiền',
    PleaseSelectSupplier: 'Vui lòng chọn nhà cung cấp',
    PleaseSelectStatut: 'Vui lòng chọn trạng thái',
    PayeBy: 'Được trả bởi',
    ChooseWarehouse: 'Chọn nhà kho',
    payNow: 'Trả tiền ngay',
    ListofCategory: 'Danh sách thể loại',
    Description: 'Sự miêu tả',
    submit: 'Gửi đi',
    ProblemCreatingThisInvoice: 'Đã xảy ra sự cố khi tạo Hóa đơn này. Vui lòng thử lại',
    ProblemPayment: 'Đã xảy ra sự cố Thanh toán. Vui lòng thử lại.',
    IncomeExpenses: 'Chi phí thu nhập',
    dailySalesPurchases: 'Bán hàng và mua hàng ngày',
    ProductsExpired: 'Sản phẩm đã hết hạn',
    ListofBrand: 'Liệt kê thương hiệu',
    CreateAdjustment: 'Tạo điều chỉnh',
    Afewwords: 'Một vài từ ...',
    UserImage: 'Hình ảnh Người dùng',
    UpdateProduct: 'Cập nhật sản phẩm',
    Brand: 'Nhãn hiệu',
    BarcodeSymbology: 'Ký hiệu mã vạch',
    ProductCost: 'Giá thành sản phẩm',
    ProductPrice: 'Giá sản phẩm',
    UnitProduct: 'Sản phẩm đơn vị',
    TaxMethod: 'Phương pháp tính thuế',
    MultipleImage: 'Nhiều hình ảnh',
    ProductHasMultiVariants: 'Sản phẩm có nhiều biến thể',
    ProductHasPromotion: 'Sản phẩm có khuyến mãi',
    PromotionStart: 'Bắt đầu Quảng cáo',
    PromotionEnd: 'Kết thúc khuyến mại',
    PromotionPrice: 'Giá khuyến mãi',
    Price: 'Giá bán',
    Cost: 'Giá cả',
    Unit: 'Đơn vị',
    ProductVariant: 'Biến thể sản phẩm',
    Variant: 'Biến thể',
    UnitPrice: 'Đơn giá',
    CreateReturnCustomer: 'Tạo khách hàng trở lại',
    EditReturnCustomer: 'Chỉnh sửa khách hàng trở lại',
    CreateReturnSupplier: 'Tạo nhà cung cấp trở lại',
    Documentation: 'Tài liệu',
    EditReturnSupplier: 'Chỉnh sửa nhà cung cấp trả hàng',
    FromWarehouse: 'Từ kho hàng',
    ToWarehouse: 'Đến nhà kho',
    EditTransfer: 'Chỉnh sửa chuyển khoản',
    TransferDetail: 'Chi tiết chuyển khoản',
    Pending: 'Đang chờ xử lý',
    Received: 'Nhận',
    Ordered: 'Đã đặt hàng',
    PermissionsManager: 'Quản lý quyền',
    BrandManager: 'Nhãn hiệu',
    BrandImage: 'Hình ảnh thương hiệu',
    BrandName: 'Thương hiệu',
    BrandDescription: 'Mô tả thương hiệu',
    BaseUnit: 'Đơn vị cơ sở',
    ManagerUnits: 'Quản lý đơn vị',
    OperationValue: 'Giá trị hoạt động',
    Operator: 'Nhà điều hành',
    Top5Products: 'Năm sản phẩm hàng đầu',
    Last5Sales: 'Năm lần bán hàng gần đây nhất',
    ListAdjustments: 'Liệt kê các điều chỉnh',
    ListTransfers: 'Chuyển danh sách',
    CreateTransfer: 'Tạo chuyển khoản',
    OrdersManager: 'Quản lý đơn hàng',
    ListQuotations: 'Liệt kê Báo giá',
    ListPurchases: 'Liệt kê các giao dịch mua',
    ListSales: 'Liệt kê doanh số',
    ListReturns: 'Danh sách trả lại',
    PeopleManager: 'Quản lý con người',



    //sweet Alert
    Delete: {
        Title: 'Bạn có chắc không?',
        Text: 'Bạn sẽ không thể hoàn nguyên điều này!',
        confirmButtonText: 'Có, xóa nó!',
        cancelButtonText: 'Huỷ bỏ',
        Deleted: 'Đã xóa!',
        Failed: 'Thất bại!',
        Therewassomethingwronge: 'Có gì đó không ổn',
        CustomerDeleted: 'Khách hàng này đã bị xóa.',
        SupplierDeleted: 'Nhà cung cấp này đã bị xóa.',
        QuoteDeleted: 'Báo giá này đã bị xóa.',
        SaleDeleted: 'Giảm giá này đã bị xóa.',
        PaymentDeleted: 'Thanh toán này đã bị xóa.',
        PurchaseDeleted: 'Giao dịch mua này đã bị xóa.',
        ReturnDeleted: 'Trở lại này đã bị xóa.',
        ProductDeleted: 'Sản phẩm này đã bị xóa.',
        ClientError: 'Khách hàng này đã được liên kết với Hoạt động khác',
        ProviderError: 'Nhà cung cấp này đã được liên kết với Hoạt động khác',
        UserDeleted: 'Người dùng này đã bị xóa.',
        UnitDeleted: 'Đơn vị này đã bị xóa.',
        RoleDeleted: 'Vai trò này đã bị xóa.',
        TaxeDeleted: 'Thuế này đã bị xóa.',
        SubCatDeleted: 'Danh mục Phụ này đã bị xóa.',
        CatDeleted: 'Danh mục này đã bị xóa.',
        WarehouseDeleted: 'Kho này đã bị xóa.',
        AlreadyLinked: 'sản phẩm này đã được liên kết với Hoạt động khác',
        AdjustDeleted: 'Điều chỉnh này đã bị xóa.',
        TitleCurrency: 'Tiền tệ này đã bị xóa.',
        TitleTransfer: 'Chuyển khoản đã được xóa thành công',
        BackupDeleted: 'Bản sao lưu đã được xóa thành công',
        TitleBrand: 'Thương hiệu này đã bị xóa',

    },
    Update: {
        TitleProfile: 'Hồ sơ của bạn đã được cập nhật thành công',
        TitleAdjust: 'Đã cập nhật điều chỉnh thành công',
        TitleRole: 'Đã cập nhật vai trò thành công',
        TitleUnit: 'Đã cập nhật đơn vị thành công',
        TitleUser: 'Đã cập nhật người dùng thành công',
        TitleCustomer: 'Cập nhật khách hàng thành công',
        TitleQuote: 'Đã cập nhật báo giá thành công',
        TitleSale: 'Đã cập nhật ưu đãi thành công',
        TitlePayment: 'Đã cập nhật thanh toán thành công',
        TitlePurchase: 'Đã cập nhật giao dịch mua thành công',
        TitleReturn: 'Đã cập nhật trở lại thành công',
        TitleProduct: 'Cập nhật sản phẩm thành công',
        TitleSupplier: 'Đã cập nhật nhà cung cấp thành công',
        TitleTaxe: 'Đã cập nhật thuế thành công',
        TitleCat: 'Đã cập nhật danh mục thành công',
        TitleWarhouse: 'Đã cập nhật kho hàng thành công',
        TitleSetting: 'Đã cập nhật cài đặt thành công',
        TitleCurrency: 'Cập nhật tiền tệ thành công',
        TitleTransfer: 'Đã cập nhật chuyển khoản thành công',
        TitleBrand: 'Thương hiệu này đã được cập nhật',
    },

    Create: {
        TitleBrand: 'Thương hiệu này đã được tạo',
        TitleRole: 'Đã tạo vai trò thành công',
        TitleUnit: 'Đã tạo đơn vị thành công',
        TitleUser: 'Người dùng được tạo thành công',
        TitleCustomer: 'Khách hàng được tạo thành công',
        TitleQuote: 'Báo giá được tạo thành công',
        TitleSale: 'Giảm giá được tạo thành công',
        TitlePayment: 'Thanh toán được tạo thành công',
        TitlePurchase: 'Mua hàng đã được tạo thành công',
        TitleReturn: 'Đã tạo trả lại thành công',
        TitleProduct: 'Sản phẩm được tạo thành công',
        TitleSupplier: 'Đã tạo nhà cung cấp thành công',
        TitleTaxe: 'Đã tạo thuế thành công',
        TitleCat: 'Đã tạo danh mục thành công',
        TitleWarhouse: 'Đã tạo kho thành công',
        TitleAdjust: 'Đã tạo điều chỉnh thành công',
        TitleCurrency: 'Tiền tệ được tạo thành công',
        TitleTransfer: 'Đã tạo chuyển khoản thành công',
    },
    Send: {
        TitleEmail: 'Gửi email thành công',
    },
    return: {
        TitleSale: 'giảm giá này đã được liên kết với một Trả lại!',
    },
    ReturnManagement: 'Quản lý trả hàng',
    ReturnDetail: 'Trả lại chi tiết',
    EditReturn: 'Chỉnh sửa lợi nhuận',
    AddReturn: 'Tạo lợi nhuận',
    EmailReturn: 'Gửi trở lại trong thư',
    DeleteReturn: 'Xóa trả lại',
    Retoursurcharge: 'Phụ phí trả hàng',
    Laivrison: 'chuyển',
    SelectSale: 'Chọn giảm giá',
    ZeroPardefault: 'Bạn có thể xóa mặt hàng hoặc đặt số lượng trả về 0 nếu hàng không được trả lại',
    Return: 'Trở về',
    Purchase: 'Mua',
    TotalSales: 'Tổng doanh số',
    TotalPurchases: 'Tổng số lần mua',
    TotalReturns: 'Tổng lợi nhuận',
    PaiementsNet: 'Thanh toán ròng',
    PaiementsSent: 'Thanh toán đã gửi',
    PaiementsReceived: 'Đã nhận thanh toán',
    Recieved: 'Nhận',
    Sent: 'Gởi',
    ProductQuantityAlerts: 'Cảnh báo số lượng sản phẩm',
    ProductCode: 'Mã',
    ProductName: 'Sản phẩm',
    AlertQuantity: 'Số lượng cảnh báo',
    WarehouseStockChart: 'Warehouse Stock Chart',
    TotalProducts: 'Tổng số sản phẩm',
    TotalQuantity: 'Tổng số lượng',
    TopCustomers: 'Năm khách hàng hàng đầu',
    TotalAmount: 'Tổng cộng',
    TotalPaid: 'Tổng số chi trả',
    CustomerSalesReport: 'Báo cáo bán hàng của khách hàng',
    CustomerPaiementsReport: 'Báo cáo thanh toán của khách hàng',
    CustomerQuotationsReport: 'Báo cáo báo giá khách hàng',
    Payments: 'Thanh toán',
    TopSuppliers: 'Năm nhà cung cấp hàng đầu',
    SupplierPurchasesReport: 'Báo cáo mua hàng của nhà cung cấp',
    SupplierPaiementsReport: 'Báo cáo thanh toán của nhà cung cấp',
    Name: 'Tên',
    Code: 'Mã',
    ManagementWarehouse: 'Quản lý kho',
    ZipCode: 'Mã Bưu Chính',
    managementCategories: 'Quản lý danh mục',
    Codecategorie: 'Loại mã',
    Namecategorie: 'Tên loại',
    Parentcategorie: 'Gia phả',
    managementTax: 'Quản lý thuế',
    TaxName: 'Tên thuế',
    TaxRate: 'Thuế suất',
    managementUnitPurchases: 'Đơn vị mua hàng',
    managementUnitSales: 'Đơn vị bán hàng',
    ShortName: 'Tên ngắn',
    PleaseSelectThesebeforeaddinganyproduct: 'Vui lòng chọn những thứ này trước khi thêm bất kỳ sản phẩm nào',
    StockAdjustement: 'Điều chỉnh chứng khoán',
    PleaseSelectWarehouse: 'Vui lòng chọn kho trước khi chọn bất kỳ sản phẩm nào',
    StockTransfer: 'Chuyển nhượng chứng khoán',

    SelectPeriod: 'Chọn khoảng thời gian',
    ThisYear: 'Năm nay',
    ThisToday: 'Hôm nay',
    ThisMonth: 'Tháng này',
    ThisWeek: 'Tuần này',

    AdjustmentDetail: 'Chi tiết điều chỉnh',
    ActivateUser: 'Người dùng này đã được kích hoạt',
    DisActivateUser: 'Người dùng này đã bị vô hiệu hóa',
    NotFound: 'Không tìm thấy trang.',
    oops: 'Lỗi! Không tìm thấy trang.',
    couldNotFind: 'Chúng tôi không thể tìm thấy trang bạn đang tìm kiếm. Trong khi đó, bạn có thể',
    ReturnDashboard: 'trở lại trang tổng quan',



     //hrm module
     hrm:'HRM',
     Employees:'Người lao động',
     Attendance:'phụng sự',
     Leave_request:'Rời khỏi Yêu cầu',
     Leave_type:'Rời khỏi loại',
     Company:'Công ty',
     Departments:'Các phòng ban',
     Designations:'chỉ tên',
     Office_Shift:'Ca văn phòng',
     Holidays:'Ngày nghỉ lễ',
     Enter_Company_Name:'Nhập tên công ty',
     Enter_email_address:'Nhập địa chỉ email',
     Enter_Company_Phone:'Nhập số điện thoại của công ty',
     Enter_Company_Country:'Nhập quốc gia của công ty',
     Created_in_successfully:'Đã được tạo thành công',
     Updated_in_successfully:'Đã cập nhật thành công',
     Deleted_in_successfully:'Đã xóa thành công',
     department:'Phòng ban',
     Enter_Department_Name:'Nhập tên phòng ban',
     Choose_Company:'Chọn công ty',
     Department_Head:'Trưởng bộ phận',
     Choose_Department_Head:'Chọn Trưởng bộ phận',
     Enter_Shift_name:'Nhập tên Shift',
     Monday_In:'Monday In',
     Monday_Out:'Monday Out',
     Tuesday_In:'Tuesday In',
     tuesday_out:'tuesday Out',
     wednesday_in:'Wednesday In',
     wednesday_out:'Wednesday Out',
     thursday_in:'Thursday In',
     thursday_out:'Thursday Out',
     friday_in:'Friday In',
     friday_out:'Friday Out',
     saturday_in:'Saturday In',
     saturday_out:'Saturday Out',
     sunday_in:'Sunday In',
     sunday_out:'Sunday Out',
     Holiday:'Ngày lễ',
     Enter_title:'Nhập tiêu đề',
     title:'Tiêu đề',
     start_date:'ngày tháng bắt đầu',
     Enter_Start_date:'Nhập ngày bắt đầu',
     Finish_Date:'Ngày kết thúc',
     Enter_Finish_date:'Nhập ngày kết thúc',
     Please_provide_any_details:'Vui lòng cung cấp bất kỳ chi tiết nào',
     Attendances:'Điểm danh',
     Enter_Attendance_date:'Nhập ngày tham dự',
     Time_In:'Time In',
     Time_Out:'Time Out',
     Choose_Employee:'Chọn Nhân viên',
     Employee:'Nhân viên',
     Work_Duration:'Thời gian làm việc',
     remaining_leaves_are_insufficient:'Số lượng lá còn lại không đủ',
     Leave_Type:'Rời khỏi loại',
     Days:'Số ngày',
     Department:'Phòng ban',
     Choose_leave_type:'Chọn rời khỏi danh mục',
     Choose_status:'Chọn trạng thái',
     Leave_Reason:'Lý do rời đi',
     Enter_Reason_Leave:'Nhập lý do nghỉ việc',
     Add_Employee:'tạo nhân viên',
     FirstName:'Tên đầu tiên',
     Enter_FirstName:'Nhập tên đầu tiên',
     LastName:'Họ',
     Enter_LastName:'Nhập họ',
     Gender:'Giới',
     Choose_Gender:'Chọn giới',
     Enter_Birth_date:'Nhập ngày sinh',
     Birth_date:'Ngày sinh',
     Enter_Country:'Nhập quốc gia',
     Enter_Phone_Number:'Nhập số điện thoại',
     joining_date:'Ngày tham gia',
     Enter_joining_date:'Nhập ngày tham gia',
     Choose_Designation:'Chọn Sự chỉ định',
     Designation:'Sự chỉ định',
     Office_Shift:'Ca văn phòng',
     Choose_Office_Shift:'Chọn ca làm việc',
     Enter_Leaving_Date:'Nhập ngày rời đi',
     Leaving_Date:'Ngày rời đi',
     Annual_Leave:'Nghỉ thường niên',
     Enter_Annual_Leave:'Nhập phép năm',
     Remaining_leave:'Thời gian còn lại',
     Employee_Details:'Chi tiết nhân viên',
     Basic_Information:'Thông tin cơ bản',
     Family_status:'Tình trạng gia đình',
     Choose_Family_status:'Chọn trạng thái Gia đình',
     Employment_type:'Loại việc làm',
     Select_Employment_type:'Chọn loại việc làm',
     Enter_City:'Nhập vào thành phố',
     Province:'Tỉnh thành',
     Enter_Province:'Nhập tỉnh',
     Enter_Address:'Nhập địa chỉ',
     Enter_Zip_code:'Nhập mã zip',
     Zip_code:'Mã zip',
     Hourly_rate:'Tỷ lệ hàng giờ',
     Enter_Hourly_rate:'Nhập giá hàng giờ',
     Basic_salary:'Lương cơ bản',
     Enter_Basic_salary:'Nhập lương cơ bản',
     Social_Media:'Truyền thông xã hội',
     Skype:'Skype',
     Enter_Skype:'đi vào Skype',
     Facebook:'Facebook',
     Enter_Facebook:'đi vào Facebook',
     WhatsApp:'WhatsApp',
     Enter_WhatsApp:'đi vào WhatsApp',
     LinkedIn:'LinkedIn',
     Enter_LinkedIn:'đi vào LinkedIn',
     Twitter:'Twitter',
     Enter_Twitter:'đi vào Twitter',
     Experiences:'Những kinh nghiệm',
     bank_account:'tài khoản ngân hàng',
     Company_Name:'Tên công ty',
     Location:'nơi',
     Enter_location:'Nhập địa điểm',
     Enter_Description:'Nhập mô tả',
     Bank_Name:'Tên ngân hàng',
     Enter_Bank_Name:'Nhập tên ngân hàng',
     Bank_Branch:'Chi nhánh ngân hàng',
     Enter_Bank_Branch:'Nhập chi nhánh ngân hàng',
     Bank_Number:'Số ngân hàng',
     Enter_Bank_Number:'Nhập số ngân hàng',
     Assigned_warehouses:'Kho được giao',
     Top_customers:'Khách hàng hàng đầu',
     Attachment:'Tập tin đính kèm',
     view_employee:'xem nhân viên',
     edit_employee:'chỉnh sửa nhân viên',
     delete_employee:'xóa nhân viên',
     Created_by : 'Được thêm bởi',
    Add_product_IMEI_Serial_number:'Thêm IMEI / Số sê-ri của sản phẩm',
    Product_Has_Imei_Serial_number:'Sản phẩm có Imei / Số sê-ri',
    IMEI_SN:'IMEI/SN',
    Shipments:'Lô hàng',
    delivered_to:'Đã gửi đến',
    shipment_ref:'Tham chiếu lô hàng',
    sale_ref:'Giới thiệu bán hàng',
    Edit_Shipping:'Chỉnh sửa Giao hàng',
    Packed:'Đóng gói',
    Shipped:'Đã vận chuyển',
    Delivered:'Đã giao hàng',
    Cancelled:'Đã hủy',
    Shipping_status:'Tình trạng giao hàng',
    Users_Report:'Báo cáo người dùng',
    stock_report:'Báo cáo hàng tồn kho',
    TotalPurchases:'Tổng số lần mua',
    Total_quotations:'Tổng số báo giá',
    Total_return_sales:'Tổng doanh số bán hàng trả lại',
    Total_return_purchases:'Tổng số lần mua hàng trả lại',
    Total_transfers:'Tổng số lần chuyển tiền',
    Total_adjustments:'Tổng số điều chỉnh',
    User_report:'Báo cáo người dùng',
    Current_stock:'Cổ phiếu hiện tại',
    product_name:'tên sản phẩm',
    Total_Customers_Due:'Tổng nợ',
    Total_Suppliers_Due:'Tổng nợ',
    Some_warehouses:'Một số nhà kho',
    All_Warehouses:'Tất cả các kho hàng',
    Product_Cost:'Giá thành sản phẩm',
    sms_settings:'Cài đặt SMS',
    pos_settings:'Cài đặt POS',
    payment_gateway:'Cổng thanh toán',
    mail_settings:'Cài đặt Thư',
    Nexmo_SMS:'Nexmo SMS',
    TWILIO_SMS:'TWILIO SMS',
    Default_SMS_Gateway:'Cổng SMS mặc định',
    module_settings:'Thiết lập mô-đun',
    Module_enabled_success:'Mô-đun được kích hoạt thành công',
    Module_Disabled_success:'Đã vô hiệu hóa mô-đun thành công',
    update_settings:'Cập nhật cài đặt',
    Please_Upload_the_Correct_Module:'Vui lòng tải lên đúng mô-đun',
    Uploaded_Success:'Đã tải lên thành công',
    Customer_details:'Chi tiết khách hàng',
    Edit_Customer:'Sửa khách hàng',
    Delete_Customer:'Xóa khách hàng',
    Pay_Due:'Trả nợ',
    Paying_amount_is_greater_than_Total_Due:'Số tiền thanh toán lớn hơn Tổng số tiền đến hạn',
    Customer_Invoice:'Hóa đơn khách hàng',
    This_Product_Not_For_Selling:'Sản phẩm này không được bán',
    Welcome_to_your_Dashboard:'Chào mừng bạn đến với Trang tổng quan của bạn',
    Total_Payable:'Tổng phải trả',
    Choose_Sale_Ref:'Chọn tài liệu tham khảo bán hàng',
    Please_Select_Sale:'vui lòng chọn đơn hàng bán',
    Sale_Ref:'Tham khảo bán hàng',
    list_product_returns:'liệt kê sản phẩm trả lại',
    Qty_return:'Qty trả lại',
    Choose_Purchase_Ref:'Chọn tham chiếu mua hàng',
    Purchase_Ref:'Tham khảo mua hàng',
    please_select_purchase:'Vui lòng chọn Mua',
    qty_return_is_greater_than_qty_purchased:'Số lượng lợi nhuận lớn hơn Qty đã mua',
    qty_return_is_greater_than_qty_sold:'Số lượng trả lại lớn hơn Số lượng đã bán',
    Quantity_sold:'Số lượng đã bán',
    qty_purchased:'Số lượng đã mua',
    Sender_Name:'Người gửi Tên',
    Upload_Module:'Tải mô-đun lên',
    The_module_must_be_uploaded_as_zip_file:'Mô-đun phải được tải lên dưới dạng tệp zip',
    All_Modules_Installed:'Tất cả các mô-đun',
    Current_Version:'Phiên bản hiện tại',
    You_already_have_the_latest_version:'Bạn đã có phiên bản mới nhất',
    Update_Available:'Cập nhật hiện có',
    Update_Log:'Nhật ký cập nhật',
    Update_Now:'Cập nhật bây giờ',
    View_Change_Log:'Xem nhật ký thay đổi',
    invoice_footer:'Chân trang hóa đơn',
    Please_Wait_for_the_update :'LƯU Ý: Vui lòng đợi bản cập nhật kết thúc hoàn toàn. Bạn không nên đóng trình duyệt hoặc dừng quá trình Nâng cấp',
    Note_update :'Lưu ý: Đảm bảo bạn sao lưu phiên bản và cơ sở dữ liệu hiện tại của mình trước khi chạy Nâng cấp, Để khôi phục nếu có lỗi',
    Time_Zone:'Múi giờ',
    Payment_note:'ghi chú thanh toán',
    sale_note:'ghi chú bán hàng',
    Total_Sale_Due:'Tổng nợ Bán hàng',
    Total_Sell_Return_Due:'Tổng doanh số bán hàng trả lại đến hạn',
    pay_all_sell_due_at_a_time:'trả tất cả bán nợ cùng một lúc',
    pay_all_sell_return_due_at_a_time:'thanh toán tất cả các khoản nợ bán hàng trả lại cùng một lúc',
    Gross_Profit:'Lợi nhuận gộp',
    pay_all_purchase_due_at_a_time:'thanh toán tất cả các khoản nợ mua hàng cùng một lúc',
    Delete_Provider:'Xóa nhà cung cấp',
    Edit_Provider:'Chỉnh sửa nhà cung cấp',
    Provider_details:'Thông tin chi tiết về nhà cung cấp',
    Total_Purchase_Due:'Tổng nợ mua hàng',
    Provider_Credit_Note:'Ghi chú tín dụng',
    Customer_Credit_Note:'Ghi chú tín dụng',
    Sell_Return:'Trở về',
    Purchase_Return:'Trở về',
    Tax_Number:'Số thuế',
    Please_add_return_quantity:'Vui lòng bổ sung số lượng trả lại',
    Return_exist_for_the_Transaction:'Trả lại tồn tại cho Giao dịch',
    qty_return_is_greater_than_Quantity_Remaining:'Số lượng Retour nhiều hơn Số lượng Còn lại',
    products_refunded_alert:'Bất kỳ sản phẩm nào có số lượng đặt thành 0 sẽ không được hoàn lại tiền',
    pay_all_purchase_return_due_at_a_time:'thanh toán tất cả các khoản trả lại hàng đến hạn tại một thời điểm',
    Total_Purchase_Return_Due:'Tổng trở lại đến hạn',
    Purchase_return_due:'Hoàn trả đến hạn',
    Sell_return_due:'Hoàn trả đến hạn',
    product_report:'báo cáo sản phẩm',
    product_sales_report:'báo cáo bán sản phẩm',
    Product_purchases_report:'Báo cáo mua sản phẩm',
    Qty_sold:'Số lượng đã bán',
    Qty_purchased:'số lượng đã mua',
    Filter_by_warehouse:'Lọc theo nhà kho',
    Enable_Print_Invoice:'In hóa đơn tự động',
    Show_Warehouse:'xem kho',

    credit_card_info: 'Thông tin thẻ tín dụng',
    Saved_Credit_Card_Info: 'Thông tin Thẻ tín dụng đã lưu',
    Credit_card_changed_successfully: 'Thay đổi thẻ tín dụng thành công',
    notification_template: 'Mẫu thông báo',
    email_notification: 'Thông báo qua email',
    sms_notification: 'Thông báo qua SMS',
    Change_product_details: 'Thay đổi chi tiết sản phẩm',
    edit_tax_and_discount_and_shipping: 'Chỉnh sửa thuế và giảm giá và vận chuyển',
    Create_Quotation_with_Stock: 'Tạo báo giá với kho hàng',
    Variant_Name: 'Tên biến thể',
    Variant_cost: 'Giá thành biến thể',
    Variant_price: 'Giá bán biến thể',
    Variant_code: 'Mã biến thể',
    Please_wait_until_the_product_is_loaded: 'Vui lòng đợi cho đến khi sản phẩm được tải',
    Choose_SMS_Gateway: 'Chọn SMS Gateway',
    Notification_Client: 'Thông báo cho khách hàng',
    Available_Tags: 'Thẻ có sẵn',
    Email_Subject: 'Chủ đề email',
    Email_body: 'Nội dung email',
    Notification_Supplier: 'Thông báo cho nhà cung cấp',
    sms_body: 'Nội dung SMS',
    sms_templates:'SMS Templates',
    email_templates:'Email Templates',
};