//Language bangla

export default {
    Receipt:'রসিদ',
    Pos_Settings:'পদ সেটিংস (রসিদ)',
    Note_to_customer:'গ্রাহককে নোট করুন',
    Show_Note_to_customer:'গ্রাহককে নোট দেখান',
    Show_barcode:'বারকোড দেখান',
    Show_Tax_and_Discount:'ট্যাক্স এবং ডিসকাউন্ট এবং শিপিং দেখান',
    Show_Customer:'গ্রাহক দেখান',
    Show_Email:'ইমেইল দেখান',
    Show_Phone:'ফোন দেখান',
    Show_Address:'ঠিকানা দেখান',
    DefaultLanguage:'নির্ধারিত ভাষা',
    footer:'ফুটার',
    Received_Amount:'প্রাপ্তির পরিমাণ',
    Paying_Amount:'পরিশোধের পরিমাণ',
    Change:'রিটার্ন পরিবর্তন',
    Paying_amount_is_greater_than_Received_amount:'পরিশোধের পরিমাণ প্রাপ্ত পরিমাণের চেয়ে বেশি',
    Paying_amount_is_greater_than_Grand_Total:'অর্থপ্রদানের পরিমাণ গ্র্যান্ড মোটের চেয়ে বেশি',
    code_must_be_not_exist_already:'কোড ইতিমধ্যে বিদ্যমান না থাকা আবশ্যক',
    You_will_find_your_backup_on:'আপনি আপনার ব্যাকআপ পাবেন',
    and_save_it_to_your_pc:'এবং আপনার পিসিতে সংরক্ষণ করুন',
    Scan_your_barcode_and_select_the_correct_symbology_below:'আপনার বারকোড স্ক্যান করুন এবং নীচের সঠিক সিম্বলজি নির্বাচন করুন',
    Scan_Search_Product_by_Code_Name:'কোড বা নাম দ্বারা পণ্য স্ক্যান/অনুসন্ধান করুন',
    Paper_size:'কাগজের আকার',
    Clear_Cache:'ক্যাশে সাফ করুন',
    Cache_cleared_successfully:'ক্যাশে সফলভাবে সাফ করা হয়েছে৷',
    Failed_to_clear_cache:'ক্যাশে সাফ করতে ব্যর্থ হয়েছে৷',
    Scan_Barcode:'বারকোড স্ক্যানার',
    Please_use_short_name_of_unit:'অনুগ্রহ করে ইউনিটের সংক্ষিপ্ত নাম ব্যবহার করুন',
    DefaultCustomer:'ডিফল্ট গ্রাহক',
    DefaultWarehouse:'ডিফল্ট গুদাম',
    Payment_Gateway:'পেমেন্ট গেটওয়ে',
    SMS_Configuration:'এসএমএস কনফিগারেশন',
    Gateway:'এসএমএস গেটওয়ে',
    Choose_Gateway:'এসএমএস গেটওয়ে নির্বাচন করুন',
    Send_SMS :'বার্তা সফলভাবে পাঠানো হয়েছে',
    sms_config_invalid:'ভুল এসএমএস কনফিগারেশন অবৈধ',
    Remove_Stripe_Key_Secret:'স্ট্রাইপ এপিআই কী মুছুন',
    credit_card_account_not_available :'ক্রেডিট কার্ড অ্যাকাউন্ট উপলব্ধ নেই',
    Credit_Card_Info:'ক্রেডিট কার্ড তথ্য',
    developed_by:'দ্বারা বিকশিত',
    Unit_already_linked_with_sub_unit:'ইউনিট ইতিমধ্যে সাব ইউনিটের সাথে লিঙ্ক করা হয়েছে৷',
    Total_Items_Quantity : 'মোট আইটেম এবং পরিমাণ',
    Value_by_Cost_and_Price : 'খরচ এবং মূল্য',
    Search_this_table: 'এই টেবিল অনুসন্ধান করুন',
    import_products:'পণ্য আমদানি করুন',
    Field_optional:'ক্ষেত্র ঐচ্ছিক',
    Download_exemple:'উদাহরণ ডাউনলোড করুন',
    field_must_be_in_csv_format:'ক্ষেত্র অবশ্যই csv ফরম্যাটে হতে হবে',
    Successfully_Imported:'সফলভাবে আমদানি করা হয়েছে',
    file_size_must_be_less_than_1_mega:'ফাইলের আকার 1 মেগা থেকে কম হতে হবে',
    Please_follow_the_import_instructions:'আমদানি নির্দেশাবলী অনুসরণ করুন',
    must_be_exist:'ইউনিট ইতিমধ্যে তৈরি করতে হবে',
    Import_Customers:'গ্রাহকদের আমদানি করুন',
    Import_Suppliers:'আমদানি সরবরাহকারী',
    Recent_Sales : 'সাম্প্রতিক বিক্রয়',
    Create_Transfer : 'ট্রান্সফার তৈরি করুন',
    order_products : 'অর্ডার আইটেম',
    Search_Product_by_Code_Name : 'কোড বা নাম দ্বারা পণ্য অনুসন্ধান করুন',
    Reports_payments_Purchase_Return:'রিপোর্ট ক্রয় রিটার্ন পেমেন্ট',
    Reports_payments_Sale_Return:'রিপোর্ট বিক্রয় রিটার্ন পেমেন্ট',
    payments_Sales_Return:'পেমেন্ট বিক্রয় রিটার্ন',
    payments_Purchases_Return:'পেমেন্ট ক্রয় রিটার্ন',
    CreateSaleReturn:'বিক্রয় রিটার্ন তৈরি করুন',
    EditSaleReturn:'বিক্রয় রিটার্ন সম্পাদনা করুন',
    SalesReturn:'বিক্রয় রিটার্ন',
    CreatePurchaseReturn:'ক্রয় রিটার্ন তৈরি করুন',
    EditPurchaseReturn:'ক্রয় রিটার্ন সম্পাদনা করুন',
    PurchasesReturn:'ক্রয় রিটার্ন',
    Due:'বাকি',
    Profit: 'লাভ',
    Revenue: 'রাজস্ব',
    Sales_today: 'আজ সেলস',
    People: 'মানুষ',
    Successfully_Created: 'সফলভাবে তৈরি',
    Successfully_Updated: 'সফলভাবে আপডেট',
    Success: 'সফলতা',
    Failed: 'ব্যর্থ হয়েছে',
    Warning: 'সতর্কবাণী',
    Please_fill_the_form_correctly: 'অনুগ্রহ করে ফর্মটি সঠিকভাবে পূরণ করুন',
    Field_is_required: 'এই ক্ষেত্র প্রয়োজন',
    Error: 'ত্রুটি!',
    you_are_not_authorized: 'দুঃখিত! আপনি অনুমোদিত নন।',
    Go_back_to_home: 'হোমপেজে ফিরে যান',
    page_not_exist: 'দুঃখিত! আপনি যে পৃষ্ঠাটি খুঁজছিলেন সেটি বিদ্যমান নেই৷',
    Choose_Status: 'স্থিতি চয়ন করুন',
    Choose_Method: 'পদ্ধতি বেছে নিন',
    Choose_Symbology: 'প্রতীকবিদ্যা চয়ন করুন',
    Choose_Category: 'বিভাগ চয়ন করুন',
    Choose_Customer: 'গ্রাহক চয়ন করুন',
    Choose_Supplier: 'সরবরাহকারী চয়ন করুন',
    Choose_Unit_Purchase: 'ক্রয় ইউনিট নির্বাচন করুন',
    Choose_Sub_Category: 'উপশ্রেণী নির্বাচন করুন',
    Choose_Brand: 'ব্র্যান্ড চয়ন করুন',
    Choose_Warehouse: 'ওয়্যারহাউস বেছে নিন',
    Choose_Unit_Sale: 'বিক্রয় ইউনিট নির্বাচন করুন',
    Enter_Product_Cost: 'পণ্য খরচ লিখুন',
    Enter_Stock_alert: 'স্টক সতর্কতা প্রবেশ করুন',
    Choose_Unit_Product: 'পণ্য ইউনিট চয়ন করুন',
    Enter_Product_Price: 'পণ্যের দাম লিখুন',
    Enter_Name_Product: 'পণ্যের নাম লিখুন',
    Enter_Role_Name: 'ভূমিকার নাম লিখুন',
    Enter_Role_Description: 'ভূমিকার বর্ণনা লিখুন',
    Enter_name_category: 'ক্যাটাগরির নাম লিখুন',
    Enter_Code_category: 'ক্যাটাগরি কোড লিখুন',
    Enter_Name_Brand: 'নাম ব্র্যান্ড লিখুন',
    Enter_Description_Brand: 'বিবরণ ব্র্যান্ড লিখুন',
    Enter_Code_Currency: 'কোড মুদ্রা লিখুন',
    Enter_name_Currency: 'নাম মুদ্রা লিখুন',
    Enter_Symbol_Currency: 'প্রতীক মুদ্রা লিখুন',
    Enter_Name_Unit: 'ইউনিটের নাম লিখুন',
    Enter_ShortName_Unit: 'সংক্ষিপ্ত নাম ইউনিট লিখুন',
    Choose_Base_Unit: 'বেস ইউনিট চয়ন করুন',
    Choose_Operator: 'অপারেটর চয়ন করুন',
    Enter_Operation_Value: 'অপারেশন মান লিখুন',
    Enter_Name_Warehouse: 'গুদাম নাম লিখুন',
    Enter_Phone_Warehouse: 'ওয়্যারহাউস ফোন লিখুন',
    Enter_Country_Warehouse: 'গুদাম দেশ লিখুন',
    Enter_City_Warehouse: 'ওয়্যারহাউস সিটিতে প্রবেশ করুন',
    Enter_Email_Warehouse: 'গুদাম ইমেল লিখুন',
    Enter_ZipCode_Warehouse: 'গুদাম জিপ কোড লিখুন',
    Choose_Currency: 'মুদ্রা পছন্দ করুন',
    Thank_you_for_your_business: 'আমাদের সাথে কেনাকাটা করার জন্য আপনাকে ধন্যবাদ . অনুগ্রহ করে আবার আসবেন',
    Cancel: 'বাতিল করুন',
    New_Customer: 'নতুন গ্রাহক',
    Incorrect_Login: 'ভুল লগইন',
    Successfully_Logged_In: 'সফলভাবে লগ ইন',
    This_user_not_active: 'এই ব্যবহারকারী সক্রিয় নয়',
    SignIn: 'সাইন ইন করুন',
    Create_an_account: 'একটি অ্যাকাউন্ট তৈরি করুন',
    Forgot_Password: 'পাসওয়ার্ড ভুলে গেছেন ?',
    Email_Address: 'ইমেইল ঠিকানা',
    SignUp: 'সাইন আপ করুন',
    Already_have_an_account: 'ইতিমধ্যে একটি একাউন্ট আছে ?',
    Reset_Password: 'পাসওয়ার্ড রিসেট করুন',
    Failed_to_authenticate_on_SMTP_server: 'মেল সার্ভারে প্রমাণীকরণ করতে ব্যর্থ হয়েছে৷',
    We_cant_find_a_user_with_that_email_addres: 'আমরা সেই ইমেল ঠিকানা সহ একজন ব্যবহারকারী খুঁজে পাচ্ছি না',
    We_have_emailed_your_password_reset_link: 'আমরা আপনার পাসওয়ার্ড রিসেট লিঙ্ক ই-মেইল করেছি',
    Please_fill_the_Email_Adress: 'অনুগ্রহ করে ইমেল ঠিকানা পূরণ করুন',
    Confirm_password: 'পাসওয়ার্ড নিশ্চিত করুন',
    Your_Password_has_been_changed: 'আপনার পাসওয়ার্ড পরিবর্তন করা হয়েছে',
    The_password_confirmation_does_not_match: 'পাসওয়ার্ড নিশ্চিতকরণ মেলে না',
    This_password_reset_token_is_invalid: 'এই পাসওয়ার্ড রিসেট টোকেন অবৈধ',
    Warehouse_report: 'গুদাম রিপোর্ট',
    All_Warehouses: 'সমস্ত গুদাম',
    Expense_List: 'সমস্ত ব্যয়',
    Expenses: 'ব্যয়',
    This_Week_Sales_Purchases: 'এই সপ্তাহে বিক্রয় এবং ক্রয়',
    Top_Selling_Products: 'শীর্ষ বিক্রয় পণ্য',
    View_all: 'সব দেখ',
    Payment_Sent_Received: 'পেমেন্ট পাঠানো এবং প্রাপ্ত',
    Filter: 'ছাঁকনি',
    Invoice_POS: 'চালান POS',
    Invoice: 'চালান',
    Customer_Info: 'গ্রাহক তথ্য',
    Company_Info: 'কোম্পানির তথ্য',
    Invoice_Info: 'চালান তথ্য',
    Order_Summary: 'অর্ডার সারাংশ',
    Quote_Info: 'কোটেশন তথ্য',
    Del: 'মুছে ফেলা',
    SuppliersPaiementsReport: 'সরবরাহকারী পেমেন্ট রিপোর্ট',
    Purchase_Info: 'ক্রয় তথ্য',
    Supplier_Info: 'সরবরাহকারীর তথ্য',
    Return_Info: 'প্রত্যাবর্তনের তথ্য',
    Expense_Category: 'বিভাগ',
    Create_Expense: 'ব্যয় তৈরি করুন',
    Details: 'বিস্তারিত',
    Discount_Method: 'ডিসকাউন্ট টাইপ',
    Net_Unit_Cost: 'নেট ইউনিট খরচ',
    Net_Unit_Price: 'নেট ইউনিট মূল্য',
    Edit_Expense: 'ব্যয় সম্পাদনা করুন',
    All_Brand: 'সমস্ত ব্র্যান্ড',
    All_Category: 'সমস্ত বিভাগ',
    ListExpenses: 'সমস্ত খরচ',
    Create_Permission: 'অনুমতি তৈরি করুন',
    Edit_Permission: 'সম্পাদনা অনুমতি',
    Reports_payments_Sales: 'পেমেন্ট বিক্রয় রিপোর্ট',
    Reports_payments_Purchases: 'রিপোর্ট পেমেন্ট ক্রয়',
    Reports_payments_Return_Customers: 'পেমেন্ট রিটার্ন গ্রাহকদের',
    Reports_payments_Return_Suppliers: 'পেমেন্ট রিটার্ন সরবরাহকারী',
    Expense_Deleted: 'এই ব্যয় মুছে ফেলা হয়েছে',
    Expense_Updated: 'এই ব্যয় আপডেট করা হয়েছে',
    Expense_Created: 'এই ব্যয় তৈরি করা হয়েছে',
    DemoVersion: 'আপনি ডেমো সংস্করণে এটি করতে পারবেন না',
    OrderStatistics: 'বিক্রয় পরিসংখ্যান',
    AlreadyAdd: 'এই পণ্য ইতিমধ্যে যোগ করা হয়েছে!!',
    AddProductToList: 'তালিকায় পণ্য যোগ করুন!',
    AddQuantity: 'অনুগ্রহ করে পণ্যের পরিমাণ যোগ করুন !!',
    InvalidData: 'অবৈধ তথ্য !!',
    LowStock: 'পরিমাণ স্টক উপলব্ধ পরিমাণ অতিক্রম',
    WarehouseIdentical: 'দুটি গুদাম একই হতে পারে না!!',
    VariantDuplicate: 'এই ভেরিয়েন্টটি ডুপ্লিকেট!!',
    Filesize: 'ফাইলের আকার',
    GenerateBackup: 'ব্যাকআপ জেনারেট করুন',
    BackupDatabase: 'ব্যাকআপ ডাটাবেস',
    Backup: 'ব্যাকআপ',
    Paid: 'পরিশোধিত পরিমাণ',
    Unpaid: 'অবৈতনিক',
    Today: 'আজ',
    Income: 'আয়',
    Expenses: 'ব্যয়',
    Sale: 'বিক্রয়',
    Actif: 'সক্রিয়',
    Inactif: 'নিষ্ক্রিয়',
    Customers: 'গ্রাহকদের',
    Phone: 'ফোন',
    SearchByPhone: 'ফোন দ্বারা অনুসন্ধান করুন',
    Suppliers: 'সরবরাহকারীদের',
    Quotations: 'কোটেশন',
    Sales: 'সেলস',
    Purchases: 'কেনাকাটা',
    Returns: 'প্রত্যাবর্তন',
    Settings: 'সেটিংস',
    SystemSettings: 'সিস্টেম সেটিংস',
    Users: 'ব্যবহারকারীদের',
    GroupPermissions: 'গ্রুপ অনুমতি',
    Currencies: 'কারেন্সি',
    Warehouses: 'গুদাম',
    Units: 'ইউনিট',
    UnitsPrchases: 'ক্রয় ইউনিট',
    UnitsSales: 'বিক্রয় ইউনিট',
    Reports: 'রিপোর্ট',
    PaymentsReport: 'পেমেন্ট রিপোর্ট',
    PaymentsPurchases: 'পেমেন্ট ক্রয়',
    PaymentsSales: 'পেমেন্ট বিক্রয়',
    ProfitandLoss: 'লাভ এবং ক্ষতি',
    WarehouseStockChart: 'গুদাম স্টক চার্ট',
    SalesReport: 'বিক্রয় প্রতিবেদন',
    PurchasesReport: 'Purchase Reportক্রয় প্রতিবেদন',
    CustomersReport: 'গ্রাহক রিপোর্ট',
    SuppliersReport: 'সরবরাহকারী রিপোর্ট',
    SupplierReport: 'সরবরাহকারী রিপোর্ট',
    DailySalesData: 'দৈনিক বিক্রয় ডেটা',
    DailyPurchasesData: 'দৈনিক ক্রয় ডেটা',
    Dernièrescinqrecords: 'সর্বশেষ পাঁচটি রেকর্ড',
    Filters: 'ফিল্টার',
    date: 'তারিখ',
    Reference: 'উল্লেখ',
    Supplier: 'সরবরাহকারী',
    PaymentStatus: 'পরিশোধের হাল',
    Customer: 'গ্রাহক',
    CustomerCode: 'গ্রাহক কোড',
    Status: 'স্ট্যাটাস',
    SupplierCode: 'সরবরাহকারী কোড',
    Categorie: 'ক্যাটাগরি',
    Categories: 'ক্যাটাগরি',
    StockTransfers: 'স্থানান্তর',
    StockManagement: 'স্টক ব্যবস্থাপনা',
    dashboard: 'ড্যাশবোর্ড',
    Products: 'পণ্য',
    productsList: 'সব পণ্য',
    ProductManagement: 'পণ্য ব্যবস্থাপনা',
    ProductQuantityAlerts: 'পণ্য পরিমাণ সতর্কতা',
    CodeProduct: 'কোড পণ্য',
    ProductTax: 'গুণফল ট্যাক্স',
    SubCategorie: 'সাব ক্যাটাগরি',
    Name_product: 'নাম',
    StockAlert: 'স্টক সতর্কতা',
    warehouse: 'গুদাম',
    Tax: 'ট্যাক্স',
    BuyingPrice: 'ক্রয় মূল্য',
    SellPrice: 'বিক্রয় মূল্য',
    Quantity: 'পরিমান',
    UnitSale: 'বিক্রয় ইউনিট',
    UnitPurchase: 'ক্রয় ইউনিট',
    ManagementCurrencies: 'মুদ্রা ব্যবস্থাপনা',
    CurrencyCode: 'মুদ্রা কোড',
    CurrencyName: 'মুদ্রার নাম',
    Symbol: 'প্রতীক',
    All: 'সব',
    EditProduct: 'পণ্য সম্পাদনা করুন',
    SearchByCode: 'কোড দ্বারা অনুসন্ধান করুন',
    SearchByName: 'নাম দ্বারা অনুসন্ধান',
    ProductDetails: 'পণ্যের বিবরণ',
    CustomerName: 'গ্রাহকের নাম',
    CustomerManagement: 'গ্রাহক ব্যবস্থাপনা',
    Add: 'তৈরি করুন',
    add: 'তৈরি করুন',
    Edit: 'সম্পাদনা',
    Close: 'ঘনিষ্ঠ',
    PleaseSelect: 'অনুগ্রহ করে নির্বাচন করুন',
    Action: 'কর্ম',
    Email: 'ইমেইল',
    EditCustomer: 'গ্রাহক সম্পাদনা করুন',
    AddCustomer: 'গ্রাহক তৈরি করুন',
    Country: 'দেশ',
    City: 'শহর',
    Adress: 'ঠিকানা',
    CustomerDetails: 'গ্রাহক বিবরণ',
    CustomersList: 'গ্রাহকদের',
    SupplierCode: 'সরবরাহকারী কোড',
    SupplierName: 'সরবরাহকারী নাম',
    SuppliersManagement: 'সরবরাহকারী ব্যবস্থাপনা',
    SupplierDetails: 'সরবরাহকারীর বিবরণ',
    QuotationsManagement: 'কোটেশন ম্যানেজমেন্ট',
    SubTotal: 'সাবটোটাল',
    MontantReste: 'পরিমাণ বাকি',
    complete: 'সম্পন্ন',
    EnAttendant: 'বিচারাধীন',
    Recu: 'গৃহীত',
    partial: 'আংশিক',
    Retournee: 'প্রত্যাবর্তন',
    DetailQuote: 'বিস্তারিত উদ্ধৃতি',
    EditQuote: 'উদ্ধৃতি সম্পাদনা করুন',
    CreateSale: 'বিক্রয় তৈরি করুন',
    DownloadPdf: 'পিডিএফ ডাউনলোড করুন',
    QuoteEmail: 'ইমেইলে উদ্ধৃতি পাঠান',
    DeleteQuote: 'মুছে ফেলা উদ্ধৃতাংশ',
    AddQuote: 'তৈরি করুন উদ্ধৃতাংশ',
    SelectProduct: 'পণ্য নির্বাচন করুন',
    ProductCodeName: 'পণ্য (কোড - নাম)',
    Price: 'দাম',
    CurrentStock: 'স্টক',
    Total: 'গ্র্যান্ড টোটাল',
    Num: 'N°',
    Unitcost: 'ইউনিট খরচ',
    to: 'To',
    Subject: 'বিষয়',
    Message: 'বার্তা',
    EmailCustomer: 'গ্রাহককে ইমেল করুন',
    Sent: 'পাঠান',
    Quote: 'উদ্ধৃতাংশ',
    Hello: 'হ্যালো',
    AttachmentQuote: 'অনুগ্রহ করে আপনার উদ্ধৃতির জন্য সংযুক্তি খুঁজুন',
    AddProducts: 'অর্ডার তালিকায় পণ্য যোগ করুন',
    SelectWarehouse: 'অনুগ্রহ করে গুদাম নির্বাচন করুন',
    SelectCustomer: 'অনুগ্রহ করে গ্রাহক নির্বাচন করুন৷',
    SalesManagement: 'বিক্রয় ব্যবস্থাপনা',
    Balance: 'ভারসাম্য',
    QtyBack: 'Qty Back',
    TotalReturn: 'টোটাল রিটার্ন',
    Amount: 'পরিমাণ',
    SaleDetail: 'বিক্রয় বিস্তারিত',
    EditSale: 'বিক্রয় সম্পাদনা করুন',
    AddSale: 'বিক্রয় তৈরি করুন',
    ShowPayment: 'পেমেন্ট দেখান',
    AddPayment: 'পেমেন্ট তৈরি করুন',
    EditPayment: 'অর্থপ্রদান সম্পাদনা করুন',
    EmailSale: 'ইমেইলে বিক্রয় পাঠান',
    DeleteSale: 'বিক্রয় মুছুন',
    ModePaiement: 'দ্বারা পরিশোধ করা হয়',
    Paymentchoice: 'পেমেন্ট পছন্দ',
    Note: 'দ্রষ্টব্য',
    PaymentComplete: 'অর্থ প্রদান সম্পূর্ণ!',
    PurchasesManagement: 'ক্রয় ব্যবস্থাপনা',
    Ordered: 'আদেশ দিয়েছেন',
    DeletePurchase: 'ক্রয় মুছুন',
    EmailPurchase: 'ইমেল এ ক্রয় পাঠান',
    EditPurchase: 'ক্রয় সম্পাদনা করুন',
    PurchaseDetail: 'ক্রয় বিস্তারিত',
    AddPurchase: 'ক্রয় তৈরি করুন',
    EmailSupplier: 'সরবরাহকারী ইমেল',
    PurchaseInvoice: 'পেমেন্ট ক্রয়',
    PurchasesInvoicesData: 'অর্থপ্রদানের ডেটা ক্রয় করে',
    SalesInvoice: 'বিক্রয় পেমেন্ট',
    SalesInvoicesData: 'বিক্রয় অর্থপ্রদানের ডেটা',
    UserManagement: 'ব্যবহারকারী ব্যবস্থাপনা',
    Firstname: 'প্রথম নাম',
    lastname: 'নামের শেষাংশ',
    username: 'ব্যবহারকারীর নাম',
    password: 'পাসওয়ার্ড',
    Newpassword: 'নতুন পাসওয়ার্ড',
    ChangeAvatar: 'অবতার পরিবর্তন করুন',
    LeaveBlank: 'আপনি যদি এটি পরিবর্তন না করে থাকেন তাহলে অনুগ্রহ করে এই ক্ষেত্রটি ফাঁকা রাখুন৷',
    type: 'টাইপ',
    UserPermissions: 'ব্যবহারকারীদের অনুমতি',
    RoleName: 'ভূমিকার নাম',
    RoleDescription: 'ভূমিকার বর্ণনা',
    AddPermissions: 'অনুমতি তৈরি করুন',
    View: 'দেখুন',
    Del: 'মুছে ফেলা',
    NewAdjustement: 'নতুন সমন্বয়',
    EditAdjustement: 'সমন্বয় সম্পাদনা করুন',
    CannotSubstraction: 'আপনি স্টক 0 আছে এমন পণ্য বিয়োগ করতে পারবেন না',
    Addition: 'সংযোজন',
    Subtraction: 'বিয়োগ',
    profil: 'প্রোফাইল',
    logout: 'প্রস্থান',
    PurchaseAlreadyPaid: 'আপনি পরিবর্তন করতে পারবেন না কারণ এই ক্রয়টি ইতিমধ্যেই অর্থপ্রদান করেছে৷',
    SaleAlreadyPaid: 'আপনি পরিবর্তন করতে পারবেন না কারণ এই বিক্রয় ইতিমধ্যেই অর্থপ্রদান করেছে৷',
    ReturnAlreadyPaid: 'আপনি পরিবর্তন করতে পারবেন না কারণ এই রিটার্ন ইতিমধ্যেই পরিশোধ করা হয়েছে',
    QuoteAlready: 'এই উদ্ধৃতি ইতিমধ্যে বিক্রয় উৎপন্ন হয়েছে',
    AddProduct: 'প্রোডাক্ট তৈরি করুন',
    QuotationComplete: 'এই উদ্ধৃতি সম্পূর্ণ',
    SiteConfiguration: 'সাইট কনফিগারেশন',
    Language: 'ভাষা',
    DefaultCurrency: 'ডিফল্ট মুদ্রা',
    LoginCaptcha: 'লগইন ক্যাপচা',
    DefaultEmail: 'ডিফল্ট ইমেল',
    SiteName: 'সাইটের নাম',
    ChangeLogo: 'লোগো পরিবর্তন করুন',
    SMTPConfiguration: 'মেইল কনফিগারেশন',
    HOST: 'হোস্ট',
    PORT: 'পোর্ট',
    encryption: 'এনক্রিপশন',
    SMTPIncorrect: 'কনফিগারেশন ভুল',
    PaymentsReturns: 'পেমেন্ট রিটার্ন',
    ReturnsInvoices: 'চালান রিটার্ন',
    ReturnsInvoicesData: 'ইনভয়েস ডেটা ফেরত দেয়',
    ShowAll: 'সমস্ত ব্যবহারকারীর সমস্ত রেকর্ড দেখুন',
    Discount: 'ছাড়',
    OrderTax: 'অর্ডার ট্যাক্স',
    Shipping: 'শিপিং',
    CompanyName: 'কোমপানির নাম',
    CompanyPhone: 'কোম্পানির ফোন',
    CompanyAdress: 'কোম্পানির ঠিকানা',
    Code: 'কোড',
    image: 'চিত্র',
    Printbarcode: 'বারকোড প্রিন্ট করুন',
    ReturnsCustomers: 'গ্রাহক রিটার্ন',
    ReturnsSuppliers: 'রিটার্ন সরবরাহকারী',
    FactureReturnCustomers: 'গ্রাহক চালান ফেরত দিন',
    FactureReturnSuppliers: 'সরবরাহকারী চালান ফেরত দিন',
    NodataAvailable: 'কোন তথ্য উপলব্ধ নেই',
    ProductImage: 'পণ্য ইমেজ',
    Barcode: 'বারকোড',
    pointofsales: 'বিক্রয় বিন্দু',
    CustomUpload: 'কাস্টম আপলোড',
    pointofsaleManagement: 'পয়েন্ট অফ সেল ম্যানেজমেন্ট',
    Adjustment: 'সমন্বয়',
    Updat: 'হালনাগাদ',
    Reset: 'রিসেট',
    print: 'প্রিন্ট',
    SearchByEmail: 'ইমেল দ্বারা অনুসন্ধান করুন',
    ChooseProduct: 'পণ্য নির্বাচন করুন',
    Qty: 'পরিমান',
    Items: 'আইটেমগুলি',
    AmountHT: 'Amount HT',
    AmountTTC: 'Amount TTC',
    PleaseSelectSupplier: 'অনুগ্রহ করে সরবরাহকারী নির্বাচন করুন',
    PleaseSelectStatut: 'দয়া করে স্থিতি নির্বাচন করুন৷',
    PayeBy: 'দ্বারা অর্থপ্রদান',
    ChooseWarehouse: 'ওয়্যারহাউস বেছে নিন',
    payNow: 'এখন পরিশোধ করুন',
    ListofCategory: 'ক্যাটাগরির তালিকা',
    Description: 'বর্ণনা',
    submit: 'জমা দিন',
    ProblemCreatingThisInvoice: 'এই চালান তৈরিতে একটি সমস্যা হয়েছে৷ অনুগ্রহপূর্বক আবার চেষ্টা করুন',
    ProblemPayment: 'পেমেন্টে সমস্যা হয়েছে। অনুগ্রহপূর্বক আবার চেষ্টা করুন.',
    IncomeExpenses: 'আয় ও ব্যয়',
    dailySalesPurchases: 'দৈনিক বিক্রয় এবং ক্রয়',
    ProductsExpired: 'পণ্যের মেয়াদ শেষ',
    ListofBrand: 'ব্র্যান্ডস',
    CreateAdjustment: 'সমন্বয় তৈরি করুন',
    Afewwords: 'কয়েক শব্দ ...',
    UserImage: 'ব্যবহারকারীর ছবি',
    UpdateProduct: 'পণ্য আপডেট করুন',
    Brand: 'ব্র্যান্ড',
    BarcodeSymbology: 'বারকোড সিম্বোলজি',
    ProductCost: 'পণ্য খরচ',
    ProductPrice: 'পণ্য মূল্য',
    UnitProduct: 'পণ্য ইউনিট',
    TaxMethod: 'ট্যাক্স প্রকার',
    MultipleImage: 'একাধিক ছবি',
    ProductHasMultiVariants: 'এই পণ্যের মাল্টি বৈকল্পিক আছে',
    ProductHasPromotion: 'পণ্য প্রচার আছে',
    PromotionStart: 'Promotion Start',
    PromotionEnd: 'Promotion End',
    PromotionPrice: 'Promotion Price',
    Price: 'দাম',
    Cost: 'খরচ',
    Unit: 'ইউনিট',
    ProductVariant: 'পণ্য বৈকল্পিক',
    Variant: 'বৈকল্পিক',
    UnitPrice: 'ইউনিট মূল্য',
    CreateReturnCustomer: 'রিটার্ন গ্রাহক তৈরি করুন',
    EditReturnCustomer: 'রিটার্ন গ্রাহক সম্পাদনা করুন',
    CreateReturnSupplier: 'রিটার্ন সরবরাহকারী তৈরি করুন',
    Documentation: 'ডক',
    EditReturnSupplier: 'রিটার্ন সরবরাহকারী সম্পাদনা করুন',
    FromWarehouse: 'গুদাম থেকে',
    ToWarehouse: 'গুদাম করার জন্য',
    EditTransfer: 'স্থানান্তর সম্পাদনা করুন',
    TransferDetail: 'স্থানান্তর বিবরণ',
    Pending: 'বিচারাধীন',
    Received: 'গৃহীত',
    Ordered: 'আদেশ দিয়েছেন',
    PermissionsManager: 'অনুমতি ব্যবস্থাপনা',
    BrandManager: 'ব্র্যান্ড',
    BrandImage: 'ব্র্যান্ড ইমেজ',
    BrandName: 'ব্র্যান্ডের নাম',
    BrandDescription: 'ব্র্যান্ডের বর্ণনা',
    BaseUnit: 'বেস একক',
    ManagerUnits: 'ইউনিট ব্যবস্থাপনা',
    OperationValue: 'অপারেশন ভ্যালু',
    Operator: 'অপারেটর',
    Top5Products: 'সেরা 5টি পণ্য',
    Last5Sales: 'শেষ 5 বিক্রয়',
    ListAdjustments: 'সমস্ত সমন্বয়',
    ListTransfers: 'সমস্ত স্থানান্তর',
    CreateTransfer: 'ট্রান্সফার তৈরি করুন',
    OrdersManager: 'আদেশ ব্যবস্থাপনা',
    ListQuotations: 'সব উদ্ধৃতি',
    ListPurchases: 'সমস্ত ক্রয়',
    ListSales: 'সমস্ত বিক্রয়',
    ListReturns: 'সব রিটার্ন',
    PeopleManager: 'মানুষ ব্যবস্থাপনা',

    //sweet Alert
    Delete: {
        Title: 'তুমি কি নিশ্চিত?',
        Text: 'আপনি এটি প্রত্যাবর্তন করতে সক্ষম হবেন না!',
        confirmButtonText: 'হ্যাঁ, এটা মুছে ফেলুন!',
        cancelButtonText: 'বাতিল করুন',
        Deleted: 'মুছে ফেলা হয়েছে!',
        Failed: 'ব্যর্থ হয়েছে!',
        Therewassomethingwronge: 'কিছু ভুল ছিল',
        CustomerDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        SupplierDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        QuoteDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        SaleDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        PaymentDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        PurchaseDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        ReturnDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        ProductDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        ClientError: 'এই ক্লায়েন্টটি ইতিমধ্যেই অন্যান্য অপারেশনের সাথে লিঙ্ক করা হয়েছে৷',
        ProviderError: 'এই সরবরাহকারী ইতিমধ্যেই অন্যান্য অপারেশনের সাথে সংযুক্ত',
        UserDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        UnitDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        RoleDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        TaxeDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        SubCatDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        CatDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        WarehouseDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        AlreadyLinked: 'এই পণ্যটি ইতিমধ্যেই অন্যান্য অপারেশনের সাথে যুক্ত',
        AdjustDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        TitleCurrency: 'সফলভাবে মুছে ফেলা হয়েছে',
        TitleTransfer: 'সফলভাবে মুছে ফেলা হয়েছে',
        BackupDeleted: 'সফলভাবে মুছে ফেলা হয়েছে',
        TitleBrand: 'সফলভাবে মুছে ফেলা হয়েছে',


    },
    Update: {
        TitleBrand: 'সফলভাবে আপডেট হয়েছে',
        TitleProfile: 'সফলভাবে আপডেট হয়েছে',
        TitleAdjust: 'সফলভাবে আপডেট হয়েছে',
        TitleRole: 'সফলভাবে আপডেট হয়েছে',
        TitleUnit: 'সফলভাবে আপডেট হয়েছে',
        TitleUser: 'সফলভাবে আপডেট হয়েছে',
        TitleCustomer: 'সফলভাবে আপডেট হয়েছে',
        TitleQuote: 'সফলভাবে আপডেট হয়েছে',
        TitleSale: 'সফলভাবে আপডেট হয়েছে',
        TitlePayment: 'সফলভাবে আপডেট হয়েছে',
        TitlePurchase: 'সফলভাবে আপডেট হয়েছে',
        TitleReturn: 'সফলভাবে আপডেট হয়েছে',
        TitleProduct: 'সফলভাবে আপডেট হয়েছে',
        TitleSupplier: 'সফলভাবে আপডেট হয়েছে',
        TitleTaxe: 'সফলভাবে আপডেট হয়েছে',
        TitleCat: 'সফলভাবে আপডেট হয়েছে',
        TitleWarhouse: 'সফলভাবে আপডেট হয়েছে',
        TitleSetting: 'সফলভাবে আপডেট হয়েছে',
        TitleCurrency: 'সফলভাবে আপডেট হয়েছে',
        TitleTransfer: 'সফলভাবে আপডেট হয়েছে',
    },

    Create: {
        TitleBrand: 'সফলভাবে তৈরি করা হয়েছে',
        TitleRole: 'সফলভাবে তৈরি করা হয়েছে',
        TitleUnit: 'সফলভাবে তৈরি করা হয়েছে',
        TitleUser: 'সফলভাবে তৈরি করা হয়েছে',
        TitleCustomer: 'সফলভাবে তৈরি করা হয়েছে',
        TitleQuote: 'সফলভাবে তৈরি করা হয়েছে',
        TitleSale: 'সফলভাবে তৈরি করা হয়েছে',
        TitlePayment: 'সফলভাবে তৈরি করা হয়েছে',
        TitlePurchase: 'সফলভাবে তৈরি করা হয়েছে',
        TitleReturn: 'সফলভাবে তৈরি করা হয়েছে',
        TitleProduct: 'সফলভাবে তৈরি করা হয়েছে',
        TitleSupplier: 'সফলভাবে তৈরি করা হয়েছে',
        TitleTaxe: 'সফলভাবে তৈরি করা হয়েছে',
        TitleCat: 'সফলভাবে তৈরি করা হয়েছে',
        TitleWarhouse: 'সফলভাবে তৈরি করা হয়েছে',
        TitleAdjust: 'সফলভাবে তৈরি করা হয়েছে',
        TitleCurrency: 'সফলভাবে তৈরি করা হয়েছে',
        TitleTransfer: 'সফলভাবে তৈরি করা হয়েছে',
    },
    Send: {
        TitleEmail: 'ইমেল সফলভাবে পাঠান',
    },
    return: {
        TitleSale: 'এই বিক্রয় ইতিমধ্যে একটি রিটার্ন সঙ্গে লিঙ্ক!',
    },
    ReturnManagement: 'রিটার্ন ম্যানেজমেন্ট',
    ReturnDetail: 'রিটার্ন বিস্তারিত',
    EditReturn: 'রিটার্ন সম্পাদনা করুন',
    AddReturn: 'রিটার্ন তৈরি করুন',
    EmailReturn: 'ইমেলে রিটার্ন পাঠান',
    DeleteReturn: 'রিটার্ন মুছুন',
    Retoursurcharge: 'রিটার্ন সারচার্জ',
    Laivrison: 'ডেলিভারি',
    SelectSale: 'বিক্রয় নির্বাচন করুন',
    ZeroPardefault: 'আপনি আইটেমটি মুছে ফেলতে পারেন বা এটি ফেরত না দিলে পরিমাণটি শূন্যে সেট করতে পারেন',
    Return: 'প্রত্যাবর্তন',
    Purchase: 'ক্রয়',
    TotalSales: 'মোট বিক্রয়',
    TotalPurchases: 'মোট ক্রয়',
    TotalReturns: 'মোট রিটার্ন',
    PaiementsNet: 'পেমেন্ট নেট',
    PaiementsSent: 'পেমেন্ট পাঠানো হয়েছে',
    PaiementsReceived: 'পেমেন্ট পেয়েছি',
    Recieved: 'গৃহীত',
    Sent: 'পাঠানো হয়েছে',
    ProductQuantityAlerts: 'পণ্য পরিমাণ সতর্কতা',
    ProductCode: 'কোড',
    ProductName: 'গুণফল',
    AlertQuantity: 'সতর্কতা পরিমাণ',
    WarehouseStockChart: 'গুদাম স্টক চার্ট',
    TotalProducts: 'মোট পণ্য',
    TotalQuantity: 'মোট পরিমাণ',
    TopCustomers: 'সেরা 5 গ্রাহক',
    TotalAmount: 'মোট পরিমাণ',
    TotalPaid: 'মোট দেওয়া',
    CustomerSalesReport: 'গ্রাহক বিক্রয় রিপোর্ট',
    CustomerPaiementsReport: 'গ্রাহক পেমেন্ট রিপোর্ট',
    CustomerQuotationsReport: 'গ্রাহক উদ্ধৃতি রিপোর্ট',
    Payments: 'পেমেন্ট',
    TopSuppliers: 'শীর্ষ 5 সরবরাহকারী',
    SupplierPurchasesReport: 'সরবরাহকারী ক্রয় রিপোর্ট',
    SupplierPaiementsReport: 'সরবরাহকারী পেমেন্ট রিপোর্ট',
    Name: 'নাম',
    Code: 'কোড',
    ManagementWarehouse: 'গুদাম ব্যবস্থাপনা',
    ZipCode: 'জিপ কোড',
    managementCategories: 'বিভাগসমূহ',
    Codecategorie: 'বিভাগ কোড',
    Namecategorie: 'বিভাগ নাম',
    Parentcategorie: 'অভিভাবক শ্রেণী',
    managementTax: 'ট্যাক্স ব্যবস্থাপনা',
    TaxName: 'ট্যাক্সের নাম',
    TaxRate: 'করের হার',
    managementUnitPurchases: 'ক্রয় ইউনিট',
    managementUnitSales: 'বিক্রয় ইউনিট',
    ShortName: 'সংক্ষিপ্ত নাম',
    PleaseSelectThesebeforeaddinganyproduct: 'কোন পণ্য যোগ করার আগে এই নির্বাচন করুন',
    StockAdjustement: 'সমন্বয়',
    PleaseSelectWarehouse: 'কোনো পণ্য নির্বাচন করার আগে গুদাম নির্বাচন করুন',
    StockTransfer: 'স্টক স্থানান্তর',
    SelectPeriod: 'পিরিয়ড নির্বাচন করুন',
    ThisYear: 'এই বছর',
    ThisToday: 'এই টুডে',
    ThisMonth: 'এই মাস',
    ThisWeek: 'এই সপ্তাহ',
    AdjustmentDetail: 'সামঞ্জস্য বিস্তারিত',
    ActivateUser: 'এই ব্যবহারকারী সক্রিয় করা হয়েছে',
    DisActivateUser: 'এই ব্যবহারকারী নিষ্ক্রিয় করা হয়েছে',
    NotFound: 'পৃষ্ঠা খুঁজে পাওয়া যায়নি.',
    oops: 'উফ! পৃষ্ঠা খুঁজে পাওয়া যায়নি.',
    couldNotFind: 'আপনি যে পৃষ্ঠাটি খুঁজছিলেন তা আমরা খুঁজে পাইনি৷ এদিকে, আপনি হতে পারেন৷',
    ReturnDashboard: 'ড্যাশবোর্ডে ফিরে যান',

    //hrm module
    hrm:'এইচআরএম',
    Employees:'কর্মচারীদের',
    Attendance:'হাজিরা',
    Leave_request:'অনুরোধ ছেড়ে দিন',
    Leave_type:'টাইপ',
    Company:'কোম্পানি',
    Departments:'বিভাগ',
    Designations:'উপাধি',
    Office_Shift:'অফিস শিফট',
    Holidays:'ছুটির দিন',
    Enter_Company_Name:'কোম্পানির নাম লিখুন',
    Enter_email_address:'ইমেইল অ্যাড্রেস দিন',
    Enter_Company_Phone:'কোম্পানির ফোন লিখুন',
    Enter_Company_Country:'কোম্পানির দেশ লিখুন',
    Created_in_successfully:'সফলভাবে তৈরি করা হয়েছে',
    Updated_in_successfully:'সফলভাবে আপডেট করা হয়েছে৷',
    Deleted_in_successfully:'সফলভাবে মুছে ফেলা হয়েছে',
    department:'বিভাগ',
    Enter_Department_Name:'বিভাগের নাম দিন',
    Choose_Company:'কোম্পানি চয়ন করুন',
    Department_Head:'বিভাগের প্রধান',
    Choose_Department_Head:'বিভাগীয় প্রধান নির্বাচন করুন',
    Enter_Shift_name:'শিফটের নাম লিখুন',
    Monday_In:'Monday In',
    Monday_Out:'Monday Out',
    Tuesday_In:'Tuesday In',
    tuesday_out:'tuesday Out',
    wednesday_in:'Wednesday In',
    wednesday_out:'Wednesday Out',
    thursday_in:'Thursday In',
    thursday_out:'Thursday Out',
    friday_in:'Friday In',
    friday_out:'Friday Out',
    saturday_in:'Saturday In',
    saturday_out:'Saturday Out',
    sunday_in:'Sunday In',
    sunday_out:'Sunday Out',
    Holiday:'ছুটি',
    Enter_title:'শিরোনাম লিখুন',
    title:'শিরোনাম',
    start_date:'শুরুর তারিখ',
    Enter_Start_date:'শুরুর তারিখ লিখুন',
    Finish_Date:'শেষ তারিখ',
    Enter_Finish_date:'সমাপ্তির তারিখ লিখুন',
    Please_provide_any_details:'কোন বিবরণ প্রদান করুন',
    Attendances:'উপস্থিতি',
    Enter_Attendance_date:'উপস্থিতির তারিখ লিখুন',
    Time_In:'Time In',
    Time_Out:'Time Out',
    Choose_Employee:'কর্মচারী নির্বাচন করুন',
    Employee:'কর্মচারী',
    Work_Duration:'কাজের সময়কাল',
    remaining_leaves_are_insufficient:'অবশিষ্ট পাতা অপর্যাপ্ত',
    Leave_Type:'টাইপ',
    Days:'দিনগুলি',
    Department:'বিভাগ',
    Choose_leave_type:'টাইপ বেছে নিন',
    Choose_status:'স্ট্যাটাস বেছে নিন',
    Leave_Reason:'যুক্তি',
    Enter_Reason_Leave:'কারণ ছুটি লিখুন',
    Add_Employee:'কর্মচারী যোগ করুন',
    FirstName:'প্রথম নাম',
    Enter_FirstName:'প্রথম নাম লিখুন',
    LastName:'নামের শেষাংশ',
    Enter_LastName:'শেষ নাম লিখুন',
    Gender:'লিঙ্গ',
    Choose_Gender:'লিঙ্গ চয়ন করুন',
    Enter_Birth_date:'জন্ম তারিখ লিখুন',
    Birth_date:'জন্ম তারিখ',
    Enter_Country:'দেশে প্রবেশ করুন',
    Enter_Phone_Number:'ফোন নম্বর লিখুন',
    joining_date:'যোগদানের তারিখ',
    Enter_joining_date:'যোগদানের তারিখ লিখুন',
    Choose_Designation:'পদবী চয়ন করুন',
    Designation:'উপাধি',
    Office_Shift:'অফিস শিফট',
    Choose_Office_Shift:'অফিস শিফট নির্বাচন করুন',
    Enter_Leaving_Date:'ছেড়ে যাওয়ার তারিখ লিখুন',
    Leaving_Date:'তারিখ ছাড়ার',
    Annual_Leave:'বার্ষিক ছুটি',
    Enter_Annual_Leave:'বার্ষিক ছুটি লিখুন',
    Remaining_leave:'অবশিষ্ট ছুটি',
    Employee_Details:'কর্মচারী বিবরণ',
    Basic_Information:'মৌলিক তথ্য',
    Family_status:'পারিবারিক মর্যাদা',
    Choose_Family_status:'পারিবারিক অবস্থা চয়ন করুন',
    Employment_type:'কর্মসংস্থান প্রকার',
    Select_Employment_type:'কর্মসংস্থানের ধরন নির্বাচন করুন',
    Enter_City:'শহরে প্রবেশ করুন',
    Province:'প্রদেশ',
    Enter_Province:'প্রদেশে প্রবেশ করুন',
    Enter_Address:'ঠিকানা লিখুন',
    Enter_Zip_code:'জিপ কোড প্রবেশ',
    Zip_code:'জিপ কোড',
    Hourly_rate:'প্রতি ঘণ্টার হার',
    Enter_Hourly_rate:'ঘন্টার হার লিখুন',
    Basic_salary:'মূল বেতন',
    Enter_Basic_salary:'বেসিক বেতন লিখুন',
    Social_Media:'সামাজিক মাধ্যম',
    Skype:'স্কাইপ',
    Enter_Skype:'স্কাইপ লিখুন',
    Facebook:'ফেসবুক',
    Enter_Facebook:'আপনার Facebook লিখুন',
    WhatsApp:'হোয়াটসঅ্যাপ',
    Enter_WhatsApp:'হোয়াটসঅ্যাপ লিখুন',
    LinkedIn:'লিঙ্কডইন',
    Enter_LinkedIn:'লিঙ্কডইন লিখুন',
    Twitter:'টুইটার',
    Enter_Twitter:'আপনার টুইটার লিখুন',
    Experiences:'অভিজ্ঞতাসমূহ',
    bank_account:'ব্যাংক হিসাব',
    Company_Name:'কোমপানির নাম',
    Location:'অবস্থান',
    Enter_location:'অবস্থান লিখুন',
    Enter_Description:'বর্ণনা লিখুন',
    Bank_Name:'ব্যাংকের নাম',
    Enter_Bank_Name:'ব্যাঙ্কের নাম লিখুন',
    Bank_Branch:'ব্যাংকের শাখা',
    Enter_Bank_Branch:'ব্যাংক শাখায় প্রবেশ করুন',
    Bank_Number:'ব্যাঙ্ক নম্বর',
    Enter_Bank_Number:'ব্যাঙ্ক নম্বর লিখুন',
    Assigned_warehouses:'অ্যাক্সেস গুদাম',
    Top_customers:'সেরা গ্রাহকদের',
    Attachment:'সংযুক্তি',
    view_employee:'কর্মচারী দেখুন',
    edit_employee:'কর্মচারী সম্পাদনা করুন',
    delete_employee:'কর্মচারী মুছুন',
    Created_by : 'দ্বারা যোগ করা',
    Add_product_IMEI_Serial_number:'পণ্যের IMEI/ক্রমিক নম্বর যোগ করুন',
    Product_Has_Imei_Serial_number:'পণ্যের রয়েছে Imei/ক্রমিক নম্বর',
    IMEI_SN:'IMEI/SN',
    Shipments:'প্রেরণ',
    delivered_to:'বিতরণ করা হয়েছে',
    shipment_ref:'ডেলিভারি রেফ',
    sale_ref:'বিক্রয় রেফ',
    Edit_Shipping:'শিপিং সম্পাদনা করুন',
    Packed:'বস্তাবন্দী',
    Shipped:'পাঠানো',
    Delivered:'বিতরণ করা হয়েছে',
    Cancelled:'বাতিল',
    Shipping_status:'শিপিং অবস্থা',
    Users_Report:'ব্যবহারকারীদের রিপোর্ট',
    stock_report:'স্টক রিপোর্ট',
    TotalPurchases:'মোট ক্রয়',
    Total_quotations:'মোট কোটেশন',
    Total_return_sales:'মোট রিটার্ন বিক্রয়',
    Total_return_purchases:'মোট রিটার্ন ক্রয়',
    Total_transfers:'মোট স্থানান্তর',
    Total_adjustments:'মোট সমন্বয়',
    User_report:'ব্যবহারকারীর প্রতিবেদন',
    Current_stock:'বর্তমান স্টক',
    product_name:'পণ্যের নাম',
    Total_Customers_Due:'মোট ঋণ',
    Total_Suppliers_Due:'মোট ঋণ',
    Some_warehouses:'কিছু গুদাম',
    All_Warehouses:'সমস্ত গুদাম',
    Product_Cost:'পণ্য খরচ',
    sms_settings:'এসএমএস সেটিংস',
    pos_settings:'POS সেটিংস',
    payment_gateway:'পেমেন্ট গেটওয়ে',
    mail_settings:'মেল সেটিংস',
    Nexmo_SMS:'নেক্সমো এসএমএস',
    TWILIO_SMS:'TWILIO SMS',
    Default_SMS_Gateway:'ডিফল্ট এসএমএস গেটওয়ে',
    module_settings:'মডিউল সেটিংস',
    Module_enabled_success:'মডিউল সফলভাবে সক্ষম হয়েছে৷',
    Module_Disabled_success:'মডিউল সফলভাবে নিষ্ক্রিয় হয়েছে৷',
    update_settings:'সেটিংস আপডেট করুন',
    Please_Upload_the_Correct_Module:'অনুগ্রহ করে সঠিক মডিউলটি আপলোড করুন',
    Uploaded_Success:'সফলভাবে আপলোড করা হয়েছে',
    Customer_details:'গ্রাহক বিবরণ',
    Edit_Customer:'গ্রাহক সম্পাদনা করুন',
    Delete_Customer:'গ্রাহক মুছুন',
    Pay_Due:'ক্লায়েন্ট বিশ্রামের পরিমাণ পরিশোধ করুন',
    Paying_amount_is_greater_than_Total_Due:'অর্থপ্রদানের পরিমাণ মোট বিশ্রামের পরিমাণের চেয়ে বেশি৷',
    Customer_Invoice:'গ্রাহক চালান',
    This_Product_Not_For_Selling:'এই পণ্যটি বিক্রির জন্য নয়',
    Welcome_to_your_Dashboard:'আপনার ড্যাশবোর্ডে স্বাগতম',
    Total_Payable:'মোট প্রদেয়',
    Choose_Sale_Ref:'বিক্রয় রেফ নির্বাচন করুন',
    Please_Select_Sale:'বিক্রয় নির্বাচন করুন',
    Sale_Ref:'বিক্রয় রেফ',
    list_product_returns:'তালিকা পণ্য রিটার্ন',
    Qty_return:'পরিমাণ রিটার্ন',
    Choose_Purchase_Ref:'ক্রয় রেফ নির্বাচন করুন',
    Purchase_Ref:'রেফ ক্রয়',
    please_select_purchase:'ক্রয় নির্বাচন করুন',
    qty_return_is_greater_than_qty_purchased:'পরিমাণ রিটার্ন ক্রয় করা পরিমাণের চেয়ে বেশি',
    qty_return_is_greater_than_qty_sold:'পরিমাণ রিটার্ন বিক্রি করা পরিমাণের চেয়ে বেশি',
    Quantity_sold:'পরিমাণ বিক্রি',
    qty_purchased:'পরিমাণ ক্রয়',
    Sender_Name:'প্রেরক নাম',
    Upload_Module:'আপলোড মডিউল',
    The_module_must_be_uploaded_as_zip_file:'মডিউলটি অবশ্যই জিপ ফাইল হিসাবে আপলোড করতে হবে',
    All_Modules_Installed:'সমস্ত মডিউল ইনস্টল',
    Current_Version:'বর্তমান সংস্করণ',
    You_already_have_the_latest_version:'আপনি ইতিমধ্যে সর্বশেষ সংস্করণ আছে',
    Update_Available:'আপডেট উপলব্ধ',
    Update_Log:'আপডেট লগ',
    Update_Now:'এখন হালনাগাদ করুন',
    View_Change_Log:'পরিবর্তন লগ দেখুন',
    Please_Wait_for_the_update :'দ্রষ্টব্য: অনুগ্রহ করে আপডেট সম্পূর্ণভাবে শেষ হওয়ার জন্য অপেক্ষা করুন। এটি ব্রাউজার বন্ধ বা একটি প্রক্রিয়া আপগ্রেড বন্ধ করার সুপারিশ করা হয় না',
    Note_update :'দ্রষ্টব্য: আপগ্রেড চালানোর আগে আপনি আপনার বর্তমান সংস্করণ এবং ডাটাবেস ব্যাকআপ করেছেন তা নিশ্চিত করুন, যদি কোনও ত্রুটি থাকে তবে এটি পুনরুদ্ধার করতে',
    invoice_footer:'চালান ফুটার',
    Time_Zone:'সময় অঞ্চল',
    Payment_note:'পেমেন্ট নোট',
    sale_note:'বিক্রয় নোট',
    Total_Sale_Due:'মোট বিক্রয় ঋণ',
    Total_Sell_Return_Due:'মোট বিক্রি রিটার্ন ঋণ',
    pay_all_sell_due_at_a_time:'এক সময়ে সমস্ত বিক্রয় ঋণ পরিশোধ করুন',
    pay_all_sell_return_due_at_a_time:'এক সময়ে সব বিক্রি রিটার্ন ঋণ পরিশোধ',
    Gross_Profit:'মোট মুনাফা',
    pay_all_purchase_due_at_a_time:'এক সময়ে সমস্ত ক্রয় ঋণ পরিশোধ করুন',
    Delete_Provider:'প্রদানকারী মুছুন',
    Edit_Provider:'প্রদানকারী সম্পাদনা করুন',
    Provider_details:'প্রদানকারীর বিবরণ',
    Total_Purchase_Due:'মোট ক্রয় দেনা',
    Provider_Credit_Note:'ক্রেডিট নোট',
    Customer_Credit_Note:'ক্রেডিট নোট',
    Sell_Return:'রিটার্ন বিক্রি করুন',
    Purchase_Return:'ক্রয় রিটার্ন',
    Tax_Number:'কর নম্বর',
    Please_add_return_quantity:'রিটার্ন পরিমাণ যোগ করুন',
    Return_exist_for_the_Transaction:'লেনদেনের জন্য রিটার্ন বিদ্যমান',
    qty_return_is_greater_than_Quantity_Remaining:'পরিমাণ রিটার্ন বাকি পরিমাণের চেয়ে বেশি',
    products_refunded_alert:'0 তে সেটের পরিমাণ সহ যেকোন পণ্য ফেরত দেওয়া হবে না',
    pay_all_purchase_return_due_at_a_time:'এক সময়ে সমস্ত ক্রয় রিটার্ন পরিশোধ করুন',
    Total_Purchase_Return_Due:'মোট ক্রয় রিটার্ন বকেয়া',
    Purchase_return_due:'ক্রয় রিটার্ন ঋণ',
    Sell_return_due:'বিক্রয় ফেরত ঋণ',
    product_report:'পণ্য রিপোর্ট',
    product_sales_report:'পণ্য বিক্রয় রিপোর্ট',
    Product_purchases_report:'পণ্য ক্রয় রিপোর্ট',
    Qty_sold:'পরিমাণ বিক্রি',
    Qty_purchased:'পরিমাণ ক্রয়',
    Filter_by_warehouse:'গুদাম দ্বারা ফিল্টার',
    Enable_Print_Invoice:'স্বয়ংক্রিয়ভাবে চালান প্রিন্ট করুন',
    Show_Warehouse:'গুদাম দেখান',
    credit_card_info : 'ক্রেডিট কার্ড তথ্য',
    saved_credit_card_info : 'সংরক্ষিত ক্রেডিট কার্ড তথ্য',
    credit_card_changed_successfully : 'ক্রেডিট কার্ড সফলভাবে পরিবর্তিত হয়েছে',
    notification_template : 'বিজ্ঞপ্তি টেমপ্লেট',
    email_notification : 'ই-মেইল বিজ্ঞপ্তি',
    sms_notification : 'এসএমএস বিজ্ঞপ্তি',
    change_product_details : 'পণ্যের বিস্তারিত পরিবর্তন করুন',
    edit_tax_and_discount_and_shipping : 'কর এবং ছাড় এবং শিপিং সম্পাদনা করুন',
    create_quotation_with_stock : 'স্টক সহ উদ্ধৃতি তৈরি করুন',
    variant_name : 'পণ্যের পদক্ষেপের নাম',
    variant_cost : 'পণ্যের পদক্ষেপ খরচ',
    variant_price : 'পণ্যের পদক্ষেপ মূল্য',
    variant_code : 'পণ্যের পদক্ষেপ কোড',
    please_wait_until_the_product_is_loaded : 'পণ্য লোড হওয়া পর্যন্ত অপেক্ষা করুন',
    choose_sms_gateway : 'এসএমএস গেটওয়ে চয়ন করুন',
    notification_client : 'বিজ্ঞপ্তি ক্লায়েন্ট',
    available_tags : 'উপলব্ধ ট্যাগগুলি',
    email_subject : 'ই-মেইল বিষয়',
    email_body : 'ই-মেইল বডি',
    notification_supplier : 'বিজ্ঞপ্তি সাপ্লাইয়ার',
    sms_body : 'এসএমএস বডি',
    sms_templates:'এসএমএস টেমপ্লেট',
    email_templates:'ইমেল টেমপ্লেট',

    
};